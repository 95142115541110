const MAX_FILE_SIZE = 1024 * 1024; //maximum file size in bytes
const SUPPORTED_FORMATS = ['image/jpeg','image/png','image/svg','image/svg+xml','image/jpg']; // supported formats

interface ValidationErrors {
  file?: string;
}
export const   validateUploadedImage = (rule: any, value: any, callback: any) => {
  if (!value) {
    return Promise.reject("Please upload an image file.");
  }
 if (value.size > MAX_FILE_SIZE) {
    return Promise.reject("Please provide image size less than 1MB.");
  }
  if (value.size === 0) {
    return Promise.reject("Image file should not be empty.");
  } 
  if (!SUPPORTED_FORMATS.includes(value.type)) {
    return Promise.reject("Please upload a PNG, SVG, JPG or JPEG image.");
  }
  return Promise.resolve();
};