import { Button, Form, Modal, Select } from "antd";
import Input from "antd/es/input/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { deleteSymbol } from "../../../redux/LivePlatform/LivePlatformAction";

type FileUploadModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  continueUnsavedChanges: () => void;
  // currentStockSymbolIndex: number;
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

function NotSaveAlertModal(props: FileUploadModalProps) {
  const dispatch = useAppDispatch();
  const stockSymbolToUpdate = useSelector((state: RootState) => state.updateStockSymbolState);
  const [form] = Form.useForm();
  const stockDropdownData = useSelector((state: RootState) => state.livePlatformState.brandDetailState.dropDownData.stockExchange);
  const brandName = useSelector((state: RootState) => state.livePlatformState.brandDetailState.data.name);
  const NotSaveAlertContent = () => {
    const onFinish = (values: any) => {
      dispatch(deleteSymbol({ ...values }));
      form.resetFields();
      props.close();
    };

    // const onFinishFailed = (errorInfo: any) => {};
    // type FieldType = {
    //   profile?: string;
    //   website?: string;
    // };
    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Unsaved Changes</h4>
        <p>You have unsaved changes. Are you sure you want to continue?</p>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-3 justify-content-end">
              <Button
                className="saveButton"
                type="primary"
                onClick={() => props.continueUnsavedChanges()}
                htmlType="submit">
                Continue
              </Button>

              <Button
                htmlType="reset"
                onClick={() => {
                  props.close();
                }}
                // form="createNewFolderForm"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      //   width={FileUploadModalWidth}
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <NotSaveAlertContent />
    </Modal>
  );
}

export default NotSaveAlertModal;
