import { ColumnsType } from "antd/es/table";
import IsDataNull from "../../Template/IsDataNull";
import Header from "../../Template/Header";
import { useState } from "react";
import OpenAuditDetails from "./OpenAuditDetails";

export interface AuditEntriesTableColumnsProperties {
  id: number;
  createdAt: string;
  createdBy: string;
  shortMessage: string;
  changes: string;
}

const AuditTableColumns: ColumnsType<AuditEntriesTableColumnsProperties> = [
  // {
  //   title: <Header data="ID" />,
  //   dataIndex: "id",

  //   defaultSortOrder: "ascend",
  //   sorter: true,
  //   width: 100,
  //   render: (data) => (
  //     <IsDataNull
  //       data={data}
  //     />
  //   ),
  // },
  {
    title: <Header data="Created At" />,
    dataIndex: "createdAt",

    defaultSortOrder: "ascend",
    sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data} />,
  },
  {
    title: <Header data={"Created By"} />,
    dataIndex: "createdBy",
    sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data} />,
  },

  {
    title: <Header data={"Description"} />,
    dataIndex: "shortMessage",
    key: "shortMessage",
    sorter: true,
    width: 300,
    render: (data) => <IsDataNull data={data} />,
  },

  {
    title: <Header data={"Changes"} />,
    dataIndex: "changes",
    width: 600,
    render: (data, record, index) => (
      <OpenAuditDetails
        data={data}
        record={record}
        index={index}
      />
    ),
  },
];

export default AuditTableColumns;
