import { Button, Form, Modal, Select } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { deleteSymbolForImageVerification, setIsEditedForImageVerification } from "../../../redux/ImageVerification/ImageVerificationAction";

type DeleteStockSymbolModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  // currentStockSymbolIndex: number;
};


function DeleteStockSymbolModal(props: DeleteStockSymbolModalProps) {
  const dispatch = useAppDispatch();
  const stockSymbolToUpdate = useSelector((state: RootState) => state.updateStockSymbolState);
  const [form] = Form.useForm();
  // const stockDropdownData = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.dropDownData.stockExchange);
  const brandName = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.name);
  const DeleteStockSymbolModalContent = () => {
    const onFinish = (values: any) => {
      dispatch(deleteSymbolForImageVerification({ ...values }));
      dispatch(setIsEditedForImageVerification(true));
      form.resetFields();
      props.close();
    };

    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Delete StockSymbol</h4>
        <p>Do you want to delete the selected StockSymbol for '{brandName}'?</p>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                symbol: stockSymbolToUpdate.symbol,
                exchangeName: stockSymbolToUpdate.exchangeName,
              }}
              colon={false}>


              <div className="d-flex gap-3 justify-content-end">
                <Button
                  className="saveButton"
                  type="primary"
                  htmlType="submit">
                  Yes
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                  }}
                  // form="createNewFolderForm"
                >
                  No
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <DeleteStockSymbolModalContent />
    </Modal>
  );
}

export default DeleteStockSymbolModal;
