import { Button, Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import {
  closeUnArchiveBrandModal,
  fetchBrandDetail,
  UnArchiveBrand,
} from "../../../redux/LivePlatform/LivePlatformAction";
import { useNavigate } from "react-router";

type ConfirmUnArchiveBrandModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  brandId: number;
};

function ConfirmUnArchiveBrandModal(props: ConfirmUnArchiveBrandModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const brandName = useSelector(
    (state: RootState) => state.livePlatformState.brandDetailState.data.name
  );
  const brandId = useSelector(
    (state: RootState) => state.livePlatformState.brandDetailState.data.id
  );
  // const navigate = useNavigate();
  const ConfirmUnArchiveBrandModalContent = () => {
    const onFinish = () => {
        dispatch(UnArchiveBrand({brandId:brandId,brandName:brandName})).then(()=>{
          dispatch(fetchBrandDetail(brandId));
          props.close();
        })
   
      // navigate("/live");
    };

    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Unarchive Brand</h4>
        <div className="d-flex flex-column gap-1">
          <p>
          Do you want to Unarchive the selected brand '{brandName}`?
          </p>

          <div className="d-flex flex-column gap-2">
            <Form
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{ remember: false }}
              colon={false}
            >
              <div className="d-flex gap-3 justify-content-end">
                <Button className="saveButton" type="primary" htmlType="submit">
                  Yes
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                    dispatch(closeUnArchiveBrandModal());
                  }}
                >
                  No
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      centered
      zIndex={10000}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}
    >
      <ConfirmUnArchiveBrandModalContent />
    </Modal>
  );
}

export default ConfirmUnArchiveBrandModal;
