import React from "react";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { FontIcon } from "@fluentui/react";
import { openDeleteStockSymbolModal, updateStockSymbol } from "../../../redux/LivePlatform/LivePlatformAction";

function OpenDeleteStockSymbolModalBtn(props: any) {
  const dispatch = useAppDispatch();

  return (
    <FontIcon
      iconName="Cancel"
      onClick={() => {
        dispatch(openDeleteStockSymbolModal());

        dispatch(updateStockSymbol({ ...props.record, index: props.index }));
      }}
    />
  );
}

export default OpenDeleteStockSymbolModalBtn;
