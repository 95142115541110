import { Button, Form, Input, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { fetchAuditEntriesDetails } from "../../../redux/LivePlatform/LivePlatformAction";

export interface AuditContainerPage {}

function AuditEntriesDetails(props: { popupVisible: boolean; setPopupVisible: () => void; index: number }) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const urlDivided = location.pathname.split("/");
  const brandId = Number(urlDivided[urlDivided.length - 1]);
  const AuditEntries = useSelector((state: RootState) => state.livePlatformState.auditEntriesState.data);
  useEffect(() => {
    dispatch(fetchAuditEntriesDetails(brandId));
  }, []);
  return (
    <div>
      <Modal
        centered
        visible={props.popupVisible}
        onCancel={() => props.setPopupVisible()}
        footer={null}
        width={600}>
        <h4>Audit Details</h4>
        <br></br>

        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 600 }}
          labelAlign={"left"}>
          <Form.Item
            label="Created At"
            name="Created At"
            colon={false}>
            <Input
              defaultValue={AuditEntries[props.index].createdAt}
              readOnly={true}
            />
          </Form.Item>

          <Form.Item
            label="Created By"
            name="Created By"
            colon={false}>
            <Input
              defaultValue={AuditEntries[props.index].createdBy}
              readOnly={true}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="Description"
            colon={false}>
            <Input
              defaultValue={AuditEntries[props.index].shortMessage}
              readOnly={true}
            />
          </Form.Item>
          <Form.Item
            label="Changes"
            name="Changes"
            colon={false}>
            <TextArea
              autoSize={{ minRows: 3, maxRows: 7 }}
              defaultValue={AuditEntries[props.index].changes.split("|||").join("\n")}
              readOnly={true}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 21 }}>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => props.setPopupVisible()}>
              Close
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AuditEntriesDetails;
