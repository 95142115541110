import { Button, Form, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import ImageViewer from "../../ImageViewer/ImageViewer";
import "./IndustryCheckTab.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { saveIndustryDetail, setIsEdited, setCurrentImageIndex } from "../../../redux/LivePlatform/LivePlatformAction";
import { fetchIndustriesList, formatIndustryList } from "../../../redux/LogoProduction/LogoProductionAction";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 24 },
    xl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 24 },
    xl: { span: 19 },
  },
};

function IndustryCheckTab(props: any) {
  const [form] = Form.useForm();

  const brandDetailState = useSelector((state: RootState) => state.livePlatformState.brandDetailState);
  useEffect(() => {
    const sectorNames = brandDetailState.data.sectors.map(sector => sector.name);
    const industriesNames = brandDetailState.data.industries.map(industry => industry.name);
    form.setFieldValue("sectors", sectorNames);
    form.setFieldValue("industries", industriesNames);
    // form.setFieldValue("industries", brandDetailState.data.industries);
 
  }, [props.tabKey]);

  const sectorList = brandDetailState.dropDownData.sectorList;
  const logoProductionStateState = useSelector((state: RootState) => state.logoProductionState);
  const listOfIndustries = logoProductionStateState.industryList.listOfIndustries;
  const arrayOfIndustries = Object.keys(listOfIndustries).map((key:any) => listOfIndustries[key]);
  const onSectorChange = async (value: any) => {
    const selectedSectors = value.map((id:any) => {
      const sector = sectorList.find((item:any) => item.value === id);
      return {
        Id: sector.key,
        Name: sector.label
      };
    });
    dispatch(
      fetchIndustriesList({
        sectorDetail: selectedSectors,
      })
    );
  };

  const isEditing: boolean = brandDetailState.isEdited;
  const dispatch = useAppDispatch();
  const pageType = "QC";

  const onFinish = (values: any) => {
    const selectedSectors = values.sectors.map((id:any) => {
      const sector = sectorList.find((item:any) => item.value === id);
      return {
        Id: sector.key,
        Name: sector.label
      };
    });
    const selectedIndustries = values.industries.map((selectedIndustry: any) => {
      // First, check if the selectedIndustry is already in brandDetailState.data.industries
      const existingIndustry = brandDetailState.data.industries.find(ind => ind.name === selectedIndustry);
      
      if (existingIndustry) {
        // If it exists, return its id and name
        return {
          Id: existingIndustry.id,
          Name: existingIndustry.name
        };
      } else {
        // Otherwise, search for it in listOfIndustries and map it
        const industry = Object.values(listOfIndustries)
          .flatMap((category: any) => category.options)
          .find(option => option.value === selectedIndustry);
        return {
          Id: industry ? industry.id : null,
          Name: industry ? industry.label : 'Unknown'
        };
      }
    });
    const industryDetail = {
      sectors: selectedSectors,
      industries:selectedIndustries,
    };
    dispatch(saveIndustryDetail({ industryDetail: industryDetail, brandId: props.brandId, brandName: props.brandName }));
    dispatch(setIsEdited(false));
  };
  return (
    <div
      className="d-flex h-100 flex-column flex-md-row"
      key={2}>
      <div className="form-container d-flex  justify-content-between   flex-column pe-0 pe-md-4  py-3 ">
        <div className="d-flex flex-column full-flex h-100 overflow-auto">
          <Spin spinning={brandDetailState.saveLoading}>
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              disabled={props.isLocked || brandDetailState.data.status === "Completed" ? true : false|| brandDetailState.data.isArchived === true
                
              }
              colon={false}
              onValuesChange={() => {
                dispatch(setIsEdited(true));
              }}
              scrollToFirstError>
              <Form.Item
                name="sectors"
                labelAlign="left"
                label="Sector"
                rules={[{ required: true, message: "Please select sector." }]}>
                <Select
                  mode="multiple"
                  placeholder="Please select a sector."
                  className="custom-multi-select"
                  // style={{ minHeight:"10%",maxHeight:"fit-content" }}
                  onChange={(value) => onSectorChange(value)}>
                  {sectorList.map((option: any, idx: number) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}>
                      <div>{option.label}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                labelAlign="left"
                label="Industry"
                name="industries"
                className="custom-multi-select"
                rules={[
                  {
                    required: true,
                    message: "Please select industry.",
                  },
                ]}>
                <Select
                  mode="tags"
                  tokenSeparators={[",", ";"]}
                  style={{ width: "100%" }}
                  disabled={!form.getFieldValue("sectors") || form.getFieldValue("sectors").length === 0 || brandDetailState.data.isArchived === true}>
                  {arrayOfIndustries.map((group:any) => (
                    <Select.OptGroup
                      key={group.label}
                      label={group.label}>
                      {group.options.map((option: any) => (
                        <Select.Option
                          key={option.value}
                          value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>

              <div className="d-flex w-100 justify-content-end">
                <div className="d-flex gap-4">
                  <Button
                    onClick={() => form.submit()}
                    className="saveButton"
                    disabled={isEditing ? false : true}
                    // disabled={brandDetailState.saveLoading}
                    type="primary">
                    Save
                  </Button>
                  <Button
                    htmlType="button"
                    disabled={brandDetailState.saveLoading}
                    onClick={() => {
                      const sectorNames = brandDetailState.data.sectors.map(sector => sector.name);
                      onSectorChange(sectorNames);
                      form.resetFields(); // Reset all fields
                      // Manually set the values for the fields you want to retain
                 
                      const industriesNames = brandDetailState.data.industries.map(industry => industry.name);
                      form.setFieldsValue({
                        sectors:sectorNames,
                        industries:industriesNames,
                      });
                    }}>
                    Clear
                  </Button>
                </div>
              </div>
            </Form>
          </Spin>
        </div>
      </div>

      <div className="responsive-border d-flex flex-column full-flex h-100 pt-3 ps-0 ps-md-4 ">
        {props.images.length > 0 ? (
          <ImageViewer
            batchId={props.batchId}
            brandId={props.brandId}
            brandDetailState={brandDetailState}
            setCurrentImageIndex={(index: number) => dispatch(setCurrentImageIndex(index))}
            pageType={pageType}
            images={props.images}
            id_key={"3"}
            visible={true}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <p className="imageNotFoundMsg">No images available</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default IndustryCheckTab;
