import { Button, Form, Modal, Select } from "antd";
import Input from "antd/es/input/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { deleteSymbol, setIsEdited } from "../../../redux/LivePlatform/LivePlatformAction";

type DeleteStockSymbolModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  // currentStockSymbolIndex: number;
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

function DeleteStockSymbolModal(props: DeleteStockSymbolModalProps) {
  const dispatch = useAppDispatch();
  const stockSymbolToUpdate = useSelector((state: RootState) => state.updateStockSymbolState);
  const [form] = Form.useForm();
  const stockDropdownData = useSelector((state: RootState) => state.livePlatformState.brandDetailState.dropDownData.stockExchange);
  const brandName = useSelector((state: RootState) => state.livePlatformState.brandDetailState.data.name);
  const EditStockSymbolContent = () => {
    const onFinish = (values: any) => {
      dispatch(deleteSymbol({ ...values }));
      dispatch(setIsEdited(true));
      form.resetFields();
      props.close();
    };
    const brandDetailState = useSelector((state: RootState) => state.livePlatformState.brandDetailState);

    // const onFinishFailed = (errorInfo: any) => {};
    // type FieldType = {
    //   profile?: string;
    //   website?: string;
    // };
    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Delete</h4>
        <p>Do you want to delete the selected StockSymbol for '{brandName}'?</p>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{
                symbol: stockSymbolToUpdate.symbol,
                exchangeName: stockSymbolToUpdate.exchangeName,
              }}
              disabled={brandDetailState.data.isArchived === true }
              colon={false}>
              {/* <Form.Item
                label="Exchange Name"
                name="exchangeName"
                labelAlign="left"
                
                rules={[
                  {
                    required: true,
                    message: "Please provide website.",
                   
                  },
                ]}>
                <Select placeholder="Please select color.">
                  {stockDropdownData.map((option, idx) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Symbol"
                name="symbol"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please select profile.",
                  },
                ]}>
                <Input placeholder="Symbol" />
              </Form.Item> */}

              <div className="d-flex gap-3 justify-content-end">
                <Button
                  className="saveButton"
                  type="primary"
                  htmlType="submit">
                  Yes
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                  }}
                  // form="createNewFolderForm"
                >
                  No
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      //   width={FileUploadModalWidth}
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <EditStockSymbolContent />
    </Modal>
  );
}

export default DeleteStockSymbolModal;
