import { Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "./StockSymbolTable.css";

interface TableComponentProps {
  dataSource: Array<any>;
  columns: Array<any>;
  height?: string;
  pageSizeOptions?: string[];
  defaultPageSize?: number;
  noPagination?: boolean;
  setIsEditing: (data: boolean) => void;
}
function TableComponent(props: TableComponentProps) {
  const [tableKey, setTableKey] = useState(0);
  const [maxRow, setMaxRow] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [pageSizeOption, setPageSizeOption] = useState([0, 0, 0]);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tableContainerHeight = tableContainerRef.current?.clientHeight as number;
    const maxRow = Math.floor(tableContainerHeight / 39) - 2;
    const maxTableHeight = maxRow * 39;
    setMaxRow(maxRow);
    setMaxHeight(maxTableHeight);
    setPageSizeOption([maxRow, maxRow * 2, maxRow * 3]);
    setTableKey((prev) => prev + 1);
  }, []);
  const [dataSource, setDataSource] = useState([
    {
      name: "Facebook",
      url: "https://www.facebook.com/brand",
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/brand",
    },
    {
      name: "Twitter",
      url: "https://www.twitter.com/brand",
    },
    {
      name: "Facebook",
      url: "https://www.facebook.com/brand",
    },
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/brand",
    },
  ]);
  return (
    <div ref={tableContainerRef}>
      <Table
        className="table-stock"
        key={tableKey}
        rowKey={() => Number(Math.random()).toString(16)}
        bordered
        showHeader={true}
        rowClassName={(record, index) => `${index % 2 === 0 ? "table-row-light" : "table-row-dark"} `}
        size="small"
        dataSource={props.dataSource}
        pagination={props.noPagination ? false : { position: ["bottomCenter"], defaultPageSize: 5, pageSizeOptions: pageSizeOption }}
        columns={props.columns}
        scroll={{ x: "max-content" }}
        sortDirections={["descend", "ascend", "descend"]}
      />
    </div>
  );
}

export default TableComponent;
