import { createSlice } from "@reduxjs/toolkit";

interface UpdateStockSymbolType {
    exchangeName: any;
    symbol: any;
  }
  
  const initialState: UpdateStockSymbolType = {
    exchangeName: "",
    symbol: "",
  };
  // Create a slice
  const updateStockSymbolSlice = createSlice({
    name: 'updateStockSymbolSlice',
    initialState,
    reducers: {
      // Action to update stock symbol
      updateStockSymbol(state, action) {
        const { exchangeName, symbol } = action.payload;
        state.symbol = symbol;
        state.exchangeName = exchangeName;
    },
      // Add more reducers for other actions if needed
    },
  });
  export default updateStockSymbolSlice.reducer;