import { validationLimitValues } from "../../../constant/constant";

export const validateNameLength = (rule: any, value: any, callback: any) => {
  const inValidcharAt = /^[^\/\\:?,<>|*]+$/;
  const specialCharsOnly = /^[^a-zA-Z0-9]+$/;
  if (value && !value.trim()) {
    return Promise.reject("Brand name cannot be empty.");
  } else {
    if (value && value.length > validationLimitValues.brandNameMaxLength) {
      callback(`Please provide brand name less than ${validationLimitValues.brandNameMaxLength} characters.`);
    }else if (value && !inValidcharAt.test(value)) {
      return Promise.reject(`Brand name cannot contain the following characters \\ / : ? , < > | *`);
    } else if (value && specialCharsOnly.test(value)) {
      return Promise.reject("Please provide a valid brand name.");
    } else {
      callback();
    }
  }
};
export const validateDomain = (rule: any, value: any, callback: any) => {
  const domainRegex = /^((https?|ftp|smtp):\/\/)?(www\.)?[A-Za-z0-9]+([-_\w]*\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/i;
  const trimmedValue = value.trim();
  const domainMatch: any = domainRegex.test(trimmedValue);
  if (value && !value.trim()) {
    return Promise.reject("Website cannot be empty.");
  } else {
    if (value && domainMatch === false) {
      callback("Please provide a valid URL without extra characters after the domain.");
      return;
    }
    callback();
  }
};
export const validateISIN = (rule: any, value: any, callback: any) => {
  if (value && !value.trim()) {
    return Promise.reject("ISIN cannot be empty.");
  }
  const ISINValue = /^[a-zA-Z0-9]{12}$/.test(value) && /[a-zA-Z]/.test(value) && /[0-9]/.test(value);
  if (!value) {
    callback();
    return;
  } else {
    if ((value && value.length !== 12) || !ISINValue) {
      callback("Please provide an ISIN with 12 alphanumeric characters");
    } else {
      callback();
    }
  }
};
