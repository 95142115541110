import { TabsProps, Tabs, Button, Menu, MenuProps } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import BackBtn from "../../components/Template/BackBtn";
import "./LivePlatformDetailPage.css";
import BrandQualityCheck from "../../components/LivePlatformDetailPageComponent/BrandQualityCheckTab/BrandQualityCheckTab";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import LoadingPage from "../templates/LoadingPage/LoadingPage";
import ImageQualityCheckTab from "../../components/LivePlatformDetailPageComponent/ImageQualityCheckTab/ImageQualityCheckTab";
import NotSaveAlertModal from "../../components/LivePlatformDetailPageComponent/ModalComponent/NotSaveAlertModal";
import AuditContainer from "../../components/LivePlatformDetailPageComponent/AuditContainer/AuditContainer";
import {
  fetchBrandDetail,
  cleanBrandDetail,
  resetAuditEntryTable,
  updateAuditRequestBody,
  fetchAuditEntriesDetails,
  openUnsavedChangesModal,
  setIsEdited,
  closeUnsavedChangesModal,
  openDeleteBrandModal,
  closeDeleteBrandModal,
  openArchiveBrandModal,
  closeArchiveBrandModal,
  openUnArchiveBrandModal,
  closeUnArchiveBrandModal,
} from "../../redux/LivePlatform/LivePlatformAction";
import IndustryContainer from "../../components/LivePlatformDetailPageComponent/IndustryCheckTab/IndustryCheckTab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import ConfirmBrandDeleteModal from "../../components/LivePlatformDetailPageComponent/ModalComponent/ConfirmBrandDeleteModal";
import ConfirmArchiveBrandModal from "../../components/LivePlatformDetailPageComponent/ModalComponent/ConfirmArchiveBrandModal";
import ConfirmUnArchiveBrandModal from "../../components/LivePlatformDetailPageComponent/ModalComponent/ConfirmUnArchiveBrandModal";

export interface LogoQualityCheckPageProps {}

function LogoQualityCheckPage(props: LogoQualityCheckPageProps) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState("0");
  const [nextKey, setNextKey] = useState("0");
  const [continueActionBy, setContinueActionBy] = useState("");
  const urlDivided = location.pathname.split("/");
  const brandId = Number(urlDivided[urlDivided.length - 1]);
  const loading = useSelector(
    (state: RootState) => state.livePlatformState.brandDetailState.loading
  );
  const brandName = useSelector(
    (state: RootState) => state.livePlatformState.brandDetailState.data.name
  );
  const apiImages = useSelector(
    (state: RootState) => state.livePlatformState.brandDetailState.data.images
  );
  const isEditing = useSelector(
    (state: RootState) => state.livePlatformState.brandDetailState.isEdited
  );
  const unsaveAlertModalOpenState = useSelector(
    (state: RootState) =>
      state.livePlatformState.brandDetailState.openUnsavedChangesModalState
  );
  const confirmBrandDeleteModalOpenState = useSelector(
    (state: RootState) =>
      state.livePlatformState.brandDetailState.openConfirmBrandDeleteModalState
  );
  const confirmArchiveBrandModalOpenState = useSelector(
    (state: RootState) =>
      state.livePlatformState.brandDetailState.openConfirmArchiveBrandModalState
  );
  const confirmUnArchiveBrandModalOpenState = useSelector(
    (state: RootState) =>
      state.livePlatformState.brandDetailState.openConfirmUnArchiveBrandModalState
  );
  const brandDetailState = useSelector((state: RootState) => state.livePlatformState.brandDetailState);
  const images = apiImages?.map((image) => {
    return {
      src: image.src,
      width: image.width,
      height: image.height,
      fileName: image.fileName,
      opacity: image.opacity,
    };
  });

  useEffect(() => {
    dispatch(fetchBrandDetail(brandId));

    // dispatch(fetchDropdownData({}));
    return () => {
      dispatch(cleanBrandDetail());
    };
  }, []);
  const auditEntryState = useSelector(
    (state: RootState) => state.livePlatformState.auditEntriesState
  );
  useEffect(() => {
    dispatch(resetAuditEntryTable());
    const RequestBody = { ...auditEntryState.currentRequestBody };
    dispatch(updateAuditRequestBody({ ...RequestBody, BrandId: brandId }));
    dispatch(fetchAuditEntriesDetails({}));
  }, []);
  useEffect(() => {
    if (key === "2") {
      dispatch(resetAuditEntryTable());
      const RequestBody = { ...auditEntryState.currentRequestBody };
      dispatch(updateAuditRequestBody({ ...RequestBody, BrandId: brandId }));
      dispatch(fetchAuditEntriesDetails({}));
    }
  }, [key]);
  const onTabChange = (key: string) => {
    if (isEditing) {
      setNextKey(key);
      setContinueActionBy("tab");
      dispatch(openUnsavedChangesModal());
    } else {
      setKey(key);
    }
  };

  const continueUnsavedChangesByTab = () => {
    setKey(nextKey);
    dispatch(setIsEdited(false));
    dispatch(closeUnsavedChangesModal());
  };
  const backActionClick = () => {
    if (isEditing) {
      setContinueActionBy("backBtn");
      dispatch(openUnsavedChangesModal());
    } else {
      navigate("/live");
    }
  };
  const continueUnsavedChangesByBackBtn = () => {
    navigate("/live");
    dispatch(setIsEdited(false));
    dispatch(closeUnsavedChangesModal());
  };

  const continueUnsavedChanges = () => {
    if (continueActionBy === "tab") {
      continueUnsavedChangesByTab();
    } else if (continueActionBy === "backBtn") {
      continueUnsavedChangesByBackBtn();
    }
  };
  const handleMenuClick = (e: any) => {
    const selectedKey = e.key;   
    if(selectedKey==="Delete Brand"){
      dispatch(openDeleteBrandModal());
    }
    if(selectedKey==="Archive Brand"){
      dispatch(openArchiveBrandModal());
    }
    if(selectedKey==="UnArchive Brand"){
      dispatch(openUnArchiveBrandModal());
    }
  };
  useEffect(() => {}, []);
  const items: TabsProps["items"] = [
    {
      key: "0",
      label: "Brand",
      children: (
        <BrandQualityCheck
          brandName={brandName}
          isLocked={false}
          brandId={brandId}
          tabKey={key}
          images={images}
        />
      ),
    },
    {
      key: "3",
      label: "Industry",
      children: (
        <IndustryContainer
          isLocked={false}
          brandId={brandId}
          brandName={brandName}
          tabKey={key}
          images={images}
          // batchId={batchId}
        />
      ),
    },
    {
      key: "1",
      label: "Images",
      children: (
        <ImageQualityCheckTab
          isLocked={false}
          brandId={brandId}
          tabKey={key}
          images={images}
        />
      ),
    },
    {
      key: "2",
      label: "Audit",
      children: <AuditContainer />,
    },
  ];
  if (loading) {
    return <LoadingPage />;
  }
  type MenuItem = Required<MenuProps>["items"][number];
  const menuitems: MenuItem[] = [
    {
      key: "sub1",
      icon: <FontAwesomeIcon icon={faEllipsisVertical}  style={{ fontSize: '20px', color: 'teal' }} />,
      children: [
        {
          key: "Delete Brand",
          label: "Delete Brand",
        },
        {
          key: brandDetailState.data.isArchived===true? "UnArchive Brand":"Archive Brand",
          label:brandDetailState.data.isArchived===true?"UnArchive Brand": "Archive Brand",
        },
      ],
    },
  ];
  return (
    <div className="body-container pt-3 h-100 gap-3">
      <NotSaveAlertModal
        openState={unsaveAlertModalOpenState}
        open={() => dispatch(openUnsavedChangesModal())}
        close={() => dispatch(closeUnsavedChangesModal())}
        continueUnsavedChanges={() => continueUnsavedChanges()}
      />
        <ConfirmBrandDeleteModal
          openState={confirmBrandDeleteModalOpenState}
        open={() => dispatch(openDeleteBrandModal())}
        close={() => dispatch(closeDeleteBrandModal())}
        brandId={brandId}
      />
        <ConfirmArchiveBrandModal
          openState={confirmArchiveBrandModalOpenState}
        open={() => dispatch(openArchiveBrandModal())}
        close={() => dispatch(closeArchiveBrandModal())}
        brandId={brandId}
      />
        <ConfirmUnArchiveBrandModal
          openState={confirmUnArchiveBrandModalOpenState}
        open={() => dispatch(openUnArchiveBrandModal())}
        close={() => dispatch(closeUnArchiveBrandModal())}
        brandId={brandId}
      />
      <div className="d-flex align-items-center gap-3">
        <BackBtn backActionClick={() => backActionClick()} backPath="/live" />
        <h3>{brandName}</h3>
      </div>

<div className="h-100 position-relative overflow-hidden">
  <Tabs
    defaultActiveKey="0"
    type="card"
    onChange={(key) => onTabChange(key)}
    activeKey={key}
    items={items}
    size="large"
    style={{ height: "100%" }}
  />
 
  {/* Menu to Archive,UnArchive & delete*/}
  {key!=="2" &&
  (
    <div style={{ position: "absolute", right: '72%', top: '0' }}>
    <Menu
    className="custom-menu"
      style={{ width: 0, border: 'none' }}
      mode="vertical"
      items={menuitems}
      expandIcon={null}
      onClick={handleMenuClick} 
    />
  </div>
  )
  }
 
</div>

    </div>
  );
}

export default LogoQualityCheckPage;
