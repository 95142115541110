import React from "react";
import "./Sidebar.css";
import { useNavigate } from "react-router";

function Sidebar(props: any) {
  const navigate = useNavigate();
  return (
    <div
      id="sidebar"
      className={`sidebar ${props.isToggle ? "" : "active"}`}>
      {/* This element is being used for show the grey screen hover on content when sidebar is opening for mobile screen */}
      <div
        className="grey-screen-sidebar"
        onClick={() => props.closeSidebar()}
      />
      <span
        className="material-symbols-outlined close-btn"
        onClick={() => props.closeSidebar()}>
        close
      </span>
      <div
        className="brand-logo-container"
        onClick={() => navigate("/live")}>
        <img
          className="gpt-logo"
          src={require("../../../../assets/img/LogoCloud_HORZ_RGB_169x32.png")}
          alt=""
        />
      </div>
      <div className="footer-sidebar">
        <a
          href="https://www.williamslea.com/"
          target="blank">
          <img
            className="wl-long-logo"
            src={"https://d38bj6fsu7xdz4.cloudfront.net/wp-content/2021/10/brand-logo.svg"}
            alt=""
          />
        </a>
      </div>
    </div>
  );
}

export default Sidebar;
