import { Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";

export default function ShowPreviewFunctionCall(props: any) {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [calculatedWidth, setCalculatedWidth] = useState<number | undefined>(undefined);
  const [calculatedHeight, setCalculatedHeight] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAndSetImage = async () => {
      setLoading(true); // Start loading

      try {
        const response = await fetch(props.ImageArray[props.index].src);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setImageSrc(url);

        // Calculate width and height after loading the image
        const img = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;

          // Default width of the image is calculated based on image scaled factor in PPT Slideshow -
          // standard 2.5 increased by 1.5, results in 3.75 inches in SlideShow
          const defaultWidthInInches = 3.75;
          const widthInPixels = defaultWidthInInches * 96; // 96 DPI
          const heightInPixels = widthInPixels / aspectRatio;

          setCalculatedWidth(widthInPixels);
          setCalculatedHeight(heightInPixels);

          setLoading(false); // Image has loaded
        };
        img.src = url;

        // Cleanup function to revoke the object URL when the component unmounts
        return () => URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error fetching and displaying image:", error);
        
      }
    };

    fetchAndSetImage();
  }, [props.ImageArray, props.index]);

  return (
    <div style={{ overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center", height: calculatedHeight || "auto" }}>
      <Tooltip
        title={props.ImageArray[props.index].fileName}
        placement="bottom"
        className="imageToolTip"
      >
        {loading ? (
          <Spin size="large"/> // Show spinner while loading
        ) : (
          <img
            src={imageSrc || undefined}
            alt={props.ImageArray[props.index].fileName}
            style={{
              width: calculatedWidth,
              height: calculatedHeight,
            }}
            className="previewImage"
          />
        )}
      </Tooltip>
    </div>
  );
}
