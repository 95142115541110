import { useSelector } from "react-redux";
import { updateStockSymbolForImageVerification, openEditStockSymbolModalForImageVerification } from "../../../redux/ImageVerification/ImageVerificationAction";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";

function OpenEditStockSymbolModalBtn(props: { data: any; record: any; index: any }) {
  const dispatch = useAppDispatch();
  const brandStatus = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.status);
  return (
    <span
      // onClick={() => props.openAddModal()}
      style={{ cursor: brandStatus === "Completed" ? "not-allowed" : "pointer" }}
      onClick={() => {
        if (brandStatus === "Completed") return;
        dispatch(updateStockSymbolForImageVerification({ ...props.record, index: props.index }));

        dispatch(openEditStockSymbolModalForImageVerification());
      }}>
      {props.data}
    </span>
  );
}

export default OpenEditStockSymbolModalBtn;
