import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import AuditTableComponent from "../../TableComponent/AuditTableComponent";
import AuditTableColumns from "./AuditTableColumns";
import { resetAuditEntryTable, updateAuditRequestBody, fetchAuditEntriesDetails } from "../../../redux/LivePlatform/LivePlatformAction";

export interface AuditContainerPage {}

function AuditContainer(props: AuditContainerPage) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const urlDivided = location.pathname.split("/");
  const brandId = Number(urlDivided[urlDivided.length - 1]);
  const AuditEntries = useSelector((state: RootState) => state.livePlatformState.auditEntriesState.data);
  const auditEntryState = useSelector((state: RootState) => state.livePlatformState.auditEntriesState);
  useEffect(() => {
    dispatch(resetAuditEntryTable());
    const RequestBody = { ...auditEntryState.currentRequestBody };
    dispatch(updateAuditRequestBody({ ...RequestBody, BrandId: brandId }));
    dispatch(fetchAuditEntriesDetails({}));
  }, []);

  const handleTableSorting = (sorter: any) => {
    const isAscend = (order: string) => {
      if (order === "ascend") {
        return true;
      }
      return false;
    };
    const RequestBody = { ...auditEntryState.currentRequestBody, sortColumn: sorter.field, isAsc: isAscend(sorter.order) };
    dispatch(resetAuditEntryTable());
    dispatch(updateAuditRequestBody({ ...RequestBody, BrandId: brandId }));
    dispatch(fetchAuditEntriesDetails({}));
  };
  return (
    <div className="body-container pt-3 h-100 gap-3">
      <div className="d-flex flex-column full-flex gap-3 ">
        <AuditTableComponent
          columns={AuditTableColumns}
          dataSource={AuditEntries}
          pagination={auditEntryState.pagination}
          handleTableSorting={handleTableSorting}
          loading={auditEntryState.loading}
          tableKey="auditContainer"
        />
      </div>
    </div>
  );
}

export default AuditContainer;
