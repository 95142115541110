import React from "react";
import ToggleBtn from "../SidebarComponent/ToggleBtn/ToggleBtn";

function QANav(props: any) {
  const user = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem("userData")))).idToken.payload;

  return (
    <nav className="navbar navbar-expand-lg  position-sticky top-0">
      <div className="container-fluid d-flex align-items-center px-4 py-2">
        <div
          className="collapse navbar-collapse justify-content-end "
          id="navbarNavAltMarkup">
          <div className="dropdown">
            <button
              className="user-container  "
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <div className="d-flex align-items-center gap-1">
                <span className="material-symbols-outlined">person</span>
                <span>{user.name}</span>
                <span className="material-symbols-outlined">arrow_drop_down</span>
              </div>
            </button>

            <div
              className="dropdown-menu drop-menu-container "
              aria-labelledby="dropdownMenuButton">
              <a
                className="dropdown-item dropdown-item-customize"
                onClick={() => console.log("logout")}
                href="/">
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default QANav;
