import { Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import BrandQualityCheck from "../../components/ImageVerificationDetailPageComponent/BrandQualityCheck/BrandQualityCheck";
import GoLiveBtn from "../../components/ImageVerificationDetailPageComponent/GoLiveBtn";
import ImageQualityCheckTab from "../../components/ImageVerificationDetailPageComponent/ImageQualityCheckTab/ImageQualityCheckTab";
import GoLiveConfirmModal from "../../components/ImageVerificationDetailPageComponent/ModalComponent/GoLiveConfirmModal";
import NotSaveAlertModal from "../../components/LivePlatformDetailPageComponent/ModalComponent/NotSaveAlertModal";
import BackBtn from "../../components/Template/BackBtn";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import LoadingPage from "../templates/LoadingPage/LoadingPage";
import { fetchBrandDetailImageVerification, cleanBrandDetailImageVerification, openUnsavedChangesModalForImageVerification, setIsEditedForImageVerification, closeUnsavedChangesModalForImageVerification, openGoLiveConfirmModal, closeGoLiveConfirmModal } from "../../redux/ImageVerification/ImageVerificationAction";
import IndustryQualityCheckTab from "../../components/ImageVerificationDetailPageComponent/IndustryQualityCheckTab/IndustryQualityCheckTab";

function ImageVerificationDetailPage(props: any) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState("0");
  const [nextKey, setNextKey] = useState("0");
  const [continueActionBy, setContinueActionBy] = useState("");
  const urlDivided = location.pathname.split("/");
  const brandId = Number(urlDivided[urlDivided.length - 1]);
  const batchId = Number(urlDivided[urlDivided.length - 2]);
  const loading = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.loading);
  const brandName = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.name);
  const apiImages = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.images);
  const isEditing = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.isEdited);
  const goLiveOpenState = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.openGoLiveConfirmModalState);
  const imageViewerLoading = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.imageViewerLoading);

  const unsaveAlertModalOpenState = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.openUnsavedChangesModalState);

  const images = apiImages?.map((image) => {
    return {
      src: image.src,
      width: image.width,
      height: image.height,
      fileName: image.fileName,
      opacity: image.opacity,
    };
  });

  useEffect(() => {
    // dispatch(setCurrentBatchId(batchId));
    dispatch(fetchBrandDetailImageVerification({ batchId, brandId }));

    // dispatch(fetchDropdownData({}));
    return () => {
      dispatch(cleanBrandDetailImageVerification());
    };
  }, []);
  const onTabChange = (key: string) => {
    if (isEditing) {
      setNextKey(key);
      setContinueActionBy("tab");
      dispatch(openUnsavedChangesModalForImageVerification());
    } else {
      setKey(key);
    }
  };

  const continueUnsavedChangesByTab = () => {
    setKey(nextKey);
    dispatch(setIsEditedForImageVerification(false));
    dispatch(closeUnsavedChangesModalForImageVerification());
  };
  const backActionClick = () => {
    if (isEditing) {
      setContinueActionBy("backBtn");
      dispatch(openUnsavedChangesModalForImageVerification());
    } else {
      navigate("/image-verification");
      // dispatch(updateRequestBodyForImageVerification({ ...requestBody, batchId: props.batchId }));
    }
  };
  const continueUnsavedChangesByBackBtn = () => {
    navigate("/image-verification");
    dispatch(setIsEditedForImageVerification(false));
    dispatch(closeUnsavedChangesModalForImageVerification());
  };

  const continueUnsavedChanges = () => {
    if (continueActionBy === "tab") {
      continueUnsavedChangesByTab();
    } else if (continueActionBy === "backBtn") {
      continueUnsavedChangesByBackBtn();
    }
  };

  useEffect(() => {}, []);
  const items: TabsProps["items"] = [
    {
      key: "0",
      label: "Brand",
      children: (
        <BrandQualityCheck
          isLocked={false}
          brandId={brandId}
          batchId={batchId}
          tabKey={key}
          images={images}
          brandName={brandName}
        />
      ),
    },
    {
      key: "2",
      label: "Industry",
      children: (
        <IndustryQualityCheckTab
        brandName={brandName}
          isLocked={false}
          brandId={brandId}
          tabKey={key}
          images={images}
          batchId={batchId}
        />
      ),
    },
    {
      key: "1",
      label: "Images",
      disabled: images.length === 0,
      children: (
        <ImageQualityCheckTab
          isLocked={false}
          brandId={brandId}
          tabKey={key}
          images={images}
          batchId={batchId}
          brandName={brandName}
        />
      ),
    },
    // {
    //   key: "2",
    //   label: "Audit",
    //   children: <AuditContainer />,
    // },
  ];
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className="body-container pt-3 h-100 gap-3">
      <NotSaveAlertModal
        openState={unsaveAlertModalOpenState}
        open={() => dispatch(openUnsavedChangesModalForImageVerification())}
        close={() => dispatch(closeUnsavedChangesModalForImageVerification())}
        continueUnsavedChanges={() => continueUnsavedChanges()}
      />
      <GoLiveConfirmModal
        openState={goLiveOpenState}
        open={() => {
          dispatch(openGoLiveConfirmModal());
        }}
        close={() => {
          dispatch(closeGoLiveConfirmModal());
        }}
        brandId={brandId}
        imageId={0}
        batchId={batchId}
        brandName={brandName}
        loading={imageViewerLoading}
      />
      <div className="d-flex align-items-center gap-3">
        <BackBtn
          backActionClick={() => backActionClick()}
          backPath="/image-verification"
        />
        <h3>{brandName}</h3>
      </div>

      <div className="h-100 position-relative overflow-hidden">
        <Tabs
          defaultActiveKey="0"
          type="card"
          onChange={(key) => onTabChange(key)}
          activeKey={key}
          tabBarExtraContent={
            <GoLiveBtn
              brandId={brandId}
              batchId={batchId}
            />
          }
          items={items}
          size="large"
          style={{ height: "100%" }}
        />
        {/* <div>
          <div className="d-flex justify-content-end gap-3"  style={{ position: "absolute", right: 0, top: 5 }}>
            <Button htmlType="submit" className="saveButton"  type="primary" onClick={() => openUnlockConfirmation()}>
              Unlock
            </Button>
            <Button htmlType="reset" className="button"  onClick={() => openGoLiveConfirmation()}>
            Go Live
            </Button>
          </div>
          </div> */}
      </div>
    </div>
  );
}

export default ImageVerificationDetailPage;
