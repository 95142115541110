import { Dropdown, Popover, Switch, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Viewer from "react-viewer";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import "./ImageViewer.css";
import FilterContainer from "./components/FilterContainer/FilterContainer";
import CustomImageViewerNavBar from "./components/customNavBar/CustomImageViewerNavBar";
import showPreviewFunctionCall from "./preview";
import { current } from "@reduxjs/toolkit";
import { FontIcon } from "@fluentui/react";
import ShowPreviewFunctionCall from "./preview";
import { downloadImage } from "../../redux/ImageVerification/ImageVerificationAction";
import { useLocation } from "react-router";
import { downloadLiveImage } from "../../redux/LivePlatform/LivePlatformAction";
export interface ImageViewerProps {
  images: { src: string; fileName: string; opacity: string }[];
  id_key: string;
  visible: boolean;
  pageType: string;
  brandDetailState: any;
  brandId: number;
  batchId: number;
  setCurrentImageIndex: (index: number) => void;
}

function ImageViewer(props: ImageViewerProps) {
  const dispatch = useAppDispatch();

  const currentIndex = props.brandDetailState.currentImageIndex;
  const currentImage = props.brandDetailState.data.images[currentIndex];

  // const imageLoading = useSelector((state: RootState) => state.livePlatformState.brandDetailState.imageViewerLoading);
  const [open, setOpen] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [images, setImages] = useState(() =>
    props.images.map((image) => image.src)
  );
  const [loadedImages, setLoadedImages] = useState<HTMLImageElement[]>([]);
  const [BackgroundColorSelected, setBackgroundColorSelected] =
    useState<string>("");
  const [onImgVerificationPage, setOnImgVerificationPage] =
    useState<boolean>(false);
  const location = useLocation();
  const items = [
    {
      key: "light",
      label: (
        <div
          onClick={() => {
            setShowPreview(true);
          }}
          className="preview-dropdown"
        >
          <FontIcon className="sun-icon" iconName="Sunny" />
          <div>Light Background</div>
        </div>
      ),
    },
    {
      key: "dark",
      label: (
        <div
          onClick={() => {
            setShowPreview(true);
          }}
          className="preview-dropdown"
        >
          <FontIcon className="moon-icon" iconName="ClearNight" />
          <div>Dark Background</div>
        </div>
      ),
    },
  ];

  const onClick = ({ key }: any) => {
    setBackgroundColorSelected(key);
  };

  const handleBgToggle = (checked: any) => {
    if (checked) {
      setBackgroundColorSelected("dark");
    } else {
      setBackgroundColorSelected("light");
    }
  };

  function toggleFullScreenOn() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(
          `Error attempting to enable full-screen mode: ${err.message} (${err.name})`
        );
      });
    }
  }

  function toggleFullScreenOff() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
    }
  }

  useEffect(() => {
    if (showPreview) {
      toggleFullScreenOn();
    }
  }, [showPreview]);

  const handlePrev = () => {
    const newIndex = currentIndex === 0 
      ? props.images.length  - 1  // If on the first image, go to the last image
      : currentIndex - 1;         // Otherwise, go to the previous image
    
    props.setCurrentImageIndex(newIndex);
    // dispatch(setCurrentImageIndex(newIndex));
  };
  
  const handleNext = () => {
    const newIndex = currentIndex === props.images.length - 1 
      ? 0  // If on the last image, go to the first image
      : currentIndex + 1;  // Otherwise, go to the next image
    
    props.setCurrentImageIndex(newIndex);
    // dispatch(setCurrentImageIndex(newIndex));
  };
  const handleSelect = (index: number) => {
    props.setCurrentImageIndex(index);
    // dispatch(setCurrentImageIndex(index));
  };

  useEffect(() => {
    // Preload images
    const loadedImages = images.map((url) => {
      const img = new Image();
      img.src = url;
      return img;
    });

    setLoadedImages(loadedImages);
  }, []);

  useEffect(() => {
    const urlDivided = location.pathname.split("/")[1];

    if (urlDivided === "image-verification") {
      setOnImgVerificationPage(true);
    } else {
      setOnImgVerificationPage(false);
    }
  }, [location]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        const newIndex = currentIndex === props.images.length - 1 
        ? 0  // Wrap around to the first image
        : currentIndex + 1;  // Move to the next image

      props.setCurrentImageIndex(newIndex);
      } else if (event.key === "ArrowLeft") {
        const newIndex = currentIndex === 0 
        ? props.images.length - 1  // Wrap around to the last image
        : currentIndex - 1;  // Move to the previous image

      props.setCurrentImageIndex(newIndex);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);

  return (
    <div
      className={"image-viewer-container-transparent gap-3"}
      id={`image-viewer-container` + props.id_key}
    >
      <div className="d-flex position-relative w-100 justify-content-center">
        <div className="actual-preview-container">
          <div className="actualLinkContainer">
            <div
              className={
                !showPreview ? "actualLink-enabled" : "actualLink-disabled"
              }
              onClick={() => setShowPreview(false)}
            >
              Actual
            </div>
          </div>
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <div className="previewContainer">
              <div
                className={
                  showPreview ? "previewLink-enabled" : "previewLink-disabled"
                }
              >
                Preview{" "}
              </div>
              <FontIcon
                className="preview-dropdown-icon"
                iconName="ChevronDown"
              />
            </div>
          </Dropdown>
        </div>

        <div className="img-navigation-container ">
        
            <div>
              <CustomImageViewerNavBar
                onNext={handleNext}
                onPrev={handlePrev}
                onSelect={handleSelect}
                images={props.images}
                activeIndex={currentIndex}
                // imgURL={images[currentIndex].src}
              />
            </div>
        </div>
      </div>

      {showPreview && (
        <>
          <div className="image-viewer-canvas">
            <div className="canvas-container">
              <div style={{ zIndex: 20 }}>
                <ShowPreviewFunctionCall
                  ImageArray={props.images}
                  index={currentIndex}
                />
              </div>
              <div
                className={
                  BackgroundColorSelected === "light"
                    ? "image-bg-light"
                    : "image-bg-dark"
                }
              ></div>
              <button
                className="save-btn"
                onClick={() => {
                  onImgVerificationPage
                    ? dispatch(
                        downloadImage({
                          imageId: currentImage.id,
                          brandId: props.brandId,
                          batchId: props.batchId,
                        })
                      )
                    : dispatch(
                        downloadLiveImage({
                          imageId: currentImage.id,
                          brandId: props.brandId,
                        })
                      );
                }}
              >
                Download image
              </button>
              <div className="toggle-theme-btn">
                <FontIcon
                  className={`sun-icon-toggle ${
                    BackgroundColorSelected === "dark" ? "icon-toggle-dark" : ""
                  }`}
                  iconName="Sunny"
                />
                <Switch
                  defaultChecked={
                    BackgroundColorSelected === "light" ? false : true
                  }
                  onChange={(e) => handleBgToggle(e)}
                />
                <FontIcon
                  className={
                    BackgroundColorSelected === "dark" ? "icon-toggle-dark" : ""
                  }
                  iconName="ClearNight"
                />
              </div>
              <div onClick={toggleFullScreenOff} className="close-btn">
                <FontIcon
                  iconName="Cancel"
                  className={`close-icon ${
                    BackgroundColorSelected === "dark" ? "close-icon-light" : ""
                  }`}
                  onClick={() => setShowPreview(false)}
                />
              </div>
            </div>
          </div>

          <div className="total-text">
            {currentIndex + 1} of {props.images.length}
          </div>
        </>
      )}
      {!showPreview && (
        <Tooltip
          title={props.images[currentIndex].fileName}
          placement="bottom"
          className="imageToolTip"
        >
          <Viewer
            noClose
            visible={props.visible}
            key={props.id_key}
            className="image-viewer"
            // images={props.images}
            images={[...props.images, { src: "", fileName: "", opacity: "" }]}
            activeIndex={currentIndex}
            onChange={(image: any, index: any) =>
              props.setCurrentImageIndex(index)
            }
            changeable={false}
            scalable={false}
            attribute={true}
            disableKeyboardSupport={true}
            // images={[{ src: "", alt: "" }]}
            noNavbar
            container={
              document.getElementById(
                `image-viewer-container` + props.id_key
              ) as HTMLElement
            }
          />
          <div className="total-text">
            {currentIndex + 1} of {props.images.length}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default ImageViewer;
