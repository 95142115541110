import React, { useEffect, useLayoutEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Footer from "../components/LayoutComponents/footer/Footer";
import AuthNav from "../components/LayoutComponents/nav/AuthNav";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../redux/Store";
import { useAppDispatch } from "../redux/useAppDispatch";
import { isUserLoggedIn } from "../services/auth/AuthAPI";
import Sidebar from "../components/LayoutComponents/SidebarComponentQA/Sidebar/Sidebar";
import QANav from "../components/LayoutComponents/nav/QANav";

function QALayout(props: any) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isToggle, setIsToggle] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const closeSidebar = () => {
    setIsToggle(true);
  };
  useLayoutEffect(() => {
    const checkUserLogin = async () => {
      // setLoading(true);
      const status = await isUserLoggedIn();

      setIsAuthenticated(status);
      setLoading(false);
    };
    checkUserLogin();
  }, []);

  if (loading) {
    return (
      <div
        className="app"
        style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return isAuthenticated ? (
    <div className="app flex-row">
      <Sidebar
        isToggle={isToggle}
        closeSidebar={() => closeSidebar()}
      />
      <div className="d-flex flex-column w-100 h-100 overflow-hidden">
        <QANav />
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/" />
  );
}

export default QALayout;
