import { ColumnsType } from "antd/es/table";

import { devToolsEnhancer } from "@reduxjs/toolkit/dist/devtoolsExtension";
import IsDataNull from "../Template/IsDataNull";
// import BrandDetailNavigationLink from "./BrandDetailNavigationLink";
import ShortenedName from "../Template/ShortenedName";
import Header from "../Template/Header";
import BrandDetailNavigationLink from "./BrandDetailNavigationLink";

const ImageVerificationTableColumns: ColumnsType<any> = [
  {
    title: <Header data="Name" />,
    dataIndex: "name",
    width: "45%",
    // defaultSortOrder: "ascend",
    sorter: true,
    render: (data, record) => (
      <BrandDetailNavigationLink
        data={data}
        record={record}
      />
    ),
  },
  {
    title: <Header data={"Website"} />,
    width: "45%",

    dataIndex: "domain",
    sorter: true,
    render: (data) => (
      <ShortenedName
        data={data}
        maxLength={30}
        toolTipText={data}
      />
    ),
  },

  {
    title: <Header data={"Image #"} />,
    dataIndex: "imagesCount",
    key: "shortMessage",
    sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data} />,
  },

  {
    title: <Header data={"Status"} />,
    dataIndex: "status",
    // defaultSortOrder: "ascend",
    sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data.replace("Awaiting Qc", "Awaiting QC")} />,
  },
];

export default ImageVerificationTableColumns;
