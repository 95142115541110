import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../services/API/ApiClient";
import { RootState } from "../Store";
import { notification } from "antd";
import { openStaticErrorNotification } from "../../components/Template/NotificationComponent";
// import { MessageType } from "./LogoProductionSlice";
export const fetchDropdownData = async () => {
  try {
    const responsePickList = await get("/pick-list", "logoCloud");
    const responseSectorPickList = await get("/pick-list/sectors", "logoCloud");
    const stockExchange = await responsePickList.StockExchanges.map((item: any) => ({ ...item, label: item.key }));
    const sectorList=await responseSectorPickList.map((item: any) => ({ ...item, label: item.value ,key:item.key}));
    const country = await responsePickList.Countries.map((item: any) => ({ ...item, label: item.key }));
    const { Countries, StockExchanges, ...imageMetaData } = responsePickList;
    const dropdownData = {
      stockExchange,
      country,
      imageMetaData,
      sectorList
    };
    return dropdownData;
  } catch (e) {
    return e;
  }
};
export const  formatIndustryList = (responseIndustryPickList: any) => {
  return Object.keys(responseIndustryPickList).map((category) => ({
    label: category,
    options: responseIndustryPickList[category].map((item: any) => ({
      id:item.key,
      value: item.value,
      label: item.value,
    })),
  }));
};
export const fetchIndustriesList = createAsyncThunk<any, { sectorDetail: any }, { state: RootState }>("logoProduction/fetchIndustriesListImageVerification", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();
    // const payload = { sectors: args.sectorDetail };
    const response = await post(`/pick-list/industries`, "logoCloud",args.sectorDetail);
    const formattedArray=formatIndustryList(response);
    dispatch(setIndustryList(formattedArray));
    return response.data;
  } catch (e: any) {
    openStaticErrorNotification("Failed to save brand details.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});


export const setIndustryList = createAction<any>("logoProduction/setIndustryList");