import { notification } from "antd";

export const openNotification = (message: string, description: any, placement: any) => {
  notification.open({
    message: (
      <div className="px-3">
        <b>{message}</b>
      </div>
    ),
    duration: 3,
    description: <div className="notification px-3">{description}</div>,
    placement,
  });
};

export const openErrorNotification = (message: string, description: any, placement: any) => {
  notification.error({
    message: (
      <div style={{ padding: "0 15px", fontWeight: "bold" }}>
        <b>{message}</b>
      </div>
    ),
    description: (
      <div style={{ padding: "0 15px" }}>
        <p>{description}</p>
      </div>
    ),
    placement,
  });
};
export const openStaticErrorNotification = (message: string, description: any, placement: any) => {
  notification.error({
    message: (
      <div style={{ padding: "0 15px", fontWeight: "bold" }}>
        <b>{message}</b>
      </div>
    ),
    description: (
      <div style={{ padding: "0 15px" }}>
        <p>{description}</p>
      </div>
    ),
    placement,
    duration: 0,
  });
};

export const openStaticErrorNotificationForCreateBrand = (message: string, description: any, placement: any) => {
  const formattedDescription = Array.isArray(description) && description.length > 0 
    ? (
        <div>
          {/* First message as a paragraph */}
          <p>{description[0]}</p>
          
          {/* rest as a list */}
          {description.length > 1 && (
            <ul>
              {description.slice(1).map((msg: string, index: number) => (
                <li key={index}>{msg}</li>
              ))}
            </ul>
          )}
        </div>
      )
    : <p>{description}</p>;

  notification.error({
    message: (
      <div style={{ padding: "0 15px", fontWeight: "bold" }}>
        <b>{message}</b>
      </div>
    ),
    description: (
      <div style={{ padding: "0 5px" }}>
        {formattedDescription}
      </div>
    ),
    placement,
    duration: 0, 
    style: {
      width: '400px',
    },
  });
};
