const region = "us-east-1";
const origin = window.location.origin;

const qa = {
  appSettingsData: {
    // generate a file format limit of video file
    federatedEngageAIProvider: "wl-qa-lc-production-ident-prov",
  },

  amplify_config: {
    Auth: {
      mandatorySignIn: true,
      region: region,
      userPoolId: "us-east-1_lXb8oGhST",
      userPoolWebClientId: "5da38195b38nioh1ie3uivg0pi",
    },
    oauth: {
      domain: "wl-qa-logocloud-production.auth.us-east-1.amazoncognito.com",
      scope: [],
      redirectSignIn: `${origin}/image-verification`,
      redirectSignOut: `${origin}/image-verification`,
      responseType: "code",
      clientId: "5da38195b38nioh1ie3uivg0pi",
    },
    API: {
      endpoints: [
        {
          name: "logoCloud",
          // endpoint: "https://api.dev.wl-dms.com",
          endpoint: "https://api.qa.production.wl-logocloud.com",
          region: region,
        },
        {
          name: "logoCloudLivePlatform",
          endpoint: "https://api.qa.production.wl-logocloud.com/live",
          region: region,
        },
        {
          name: "logoCloudImageVerification",
          endpoint: "https://api.qa.production.wl-logocloud.com/image-verifications",
          region: region,
        },
      ],
    },
  },
};
// type qaType = typeof qa

export type QaType = typeof qa;
export default qa;
