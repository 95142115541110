import { Switch } from "antd";
import React, { useEffect } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { imageVerificationAutoRefresh, switchAutoFreshForImageVerification } from "../../redux/ImageVerification/ImageVerificationAction";

function AutoRefreshCheckboxForImageVerification(props: any) {
  const dispatch = useAppDispatch();
  const autoRefreshState = useSelector((state: RootState) => state.imageVerificationState.autoRefresh);
  useEffect(() => {
    dispatch(imageVerificationAutoRefresh({}));
  }, [autoRefreshState.autoRefresh]);
  return (
    <div className="auto-refresh-checkbox h-100 gap-2 ">
      <SyncOutlined
        style={{ fontSize: 15, visibility: autoRefreshState.loading ? "visible" : "hidden" }}
        spin
      />
      <Switch
        size="default"
        defaultChecked={autoRefreshState.autoRefresh}
        onChange={(checked) => {
          dispatch(switchAutoFreshForImageVerification({ autoRefresh: checked }));
        }}
      />

      <span>Auto Refresh</span>
    </div>
  );
}

export default AutoRefreshCheckboxForImageVerification;
