import React from "react";
import { useNavigate } from "react-router";

function BrandDetailNavigationLink(props: any) {
  const navigate = useNavigate();
  return (
    <div
      className="link"
      onClick={() => navigate(`detail/${props.record.id}`)}>
      {props.data}
    </div>
  );
}

export default BrandDetailNavigationLink;
