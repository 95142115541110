export const defaultRequestBody = {
  keyword: "",
  pageNum: 1,
  pageSize: 30,
  sortColumn: "",
  isAsc: true,
  includeArchiveBrands:false,
};

export const defaultAuditRequestBody = {
  BrandId: 0,
  pageNum: 1,
  pageSize: 30,
  sortColumn: "createdAt",
  isAsc: false,
};

export const defaultImageVerificationRequestBody = {
  batchId: 0,
  keyword: "",
  pageNum: 1,
  pageSize: 30,
  sortColumn: "",
  isAsc: true,
};

const brandDetailResponse = {
  id: 1,
  name: "Vodafone",
  isin: "",
  domain: "http://vodafone.com",
  description: "Vodafone is a mobile telecommunications company that offers voice, messaging, and data services.",
  // country: "",
  status: "Completed",
  isArchived:false,
  isDummyDomain:false,
  batchId: 0,
  images: [
    {
      id: 1,
      type: "icon",
      src: "live/V/Vodafone/icon/idNZKFMQtS.jpeg",
      opacity: "",
      format: "jpeg",
      size: 10232,
      height: 399,
      width: 399,
      color: "",
      theme:"",
      status: "Live",
      comments: "",
      fileName: "",
      isArchived: false,
    },
  ],

  stockSymbols: [] as Array<any>,
  sectors:[] as Array<any>,
  industries:[]as Array<any>,
  tags: [],
  brandLocation: {
    id: 4,
    brandId: 314436,
    city: "",
    state: "",
    country: "",
    countryCode: "",
    subRegion: "",
    region: ""
  }
  
};

const image = {
  id: 1,
  type: "icon",
  src: "live/V/Vodafone/icon/idNZKFMQtS.jpeg",
  opacity: "",
  format: "jpeg",
  size: 10232,
  height: 399,
  width: 399,
  color: "",
  status: "Live",
  comments: "",
  fileName: "",
  isArchived: false,
};
export type DefaultRequestBodyType = typeof defaultRequestBody;
export type DefaultAuditRequestBodyType = typeof defaultAuditRequestBody;
export type BrandDetailResponseType = typeof brandDetailResponse;
export type DefaultImageVerificationRequestBodyType = typeof defaultImageVerificationRequestBody;
export type ImageType = typeof image;
