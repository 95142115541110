import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { post, get } from "../../services/API/ApiClient";
import { RootState } from "../Store";
import { MessageType } from "./ChatAISlice";

///////////////////Start for QA LC production
export const fetchAnswer = createAsyncThunk<any, string, { state: RootState }>("chatAI/fetchAnswer", async (question, { getState, dispatch }) => {
  try {
    const requestBody = {
      question: question,
    };
    const responseData = await post("/qna/query", "logoCloud", requestBody);

    dispatch(setMessages({ message: responseData.answer, owner: "bot", sqlQuery: responseData.sql_query }));

    return responseData;
  } catch (err: any) {
    throw new Error(err.response?.data ?? "Failed to fetch file");
  }
});

export const fetchSuggestQuestion = createAsyncThunk<any, any, { state: RootState }>("chatAI/fetchSuggestQuestion", async (question, { getState, dispatch }) => {
  try {
    const response = await get("/qna-suggested-questions", "logoCloud");
    return response;
  } catch (err: any) {
    throw new Error(err.response?.data ?? "Failed to fetch file");
  }
});

export const updateCurrentRepliedMessage = createAction("chatAI/updateCurrentRepliedMessage", (message, messageType, processingTime?) => {
  return { payload: { message: message, messageType: messageType, processingTime: processingTime } };
});
export const setMessages = createAction<MessageType>("chatAI/setMessages");
export const clearMessages = createAction("chatAI/clearMessages");
export const openSourceDetail = createAction("chatAI/openSourceDetail");
export const closeSourceDetail = createAction("chatAI/closeSourceDetail");
/////////////End of QA LC production
