import { createSlice } from "@reduxjs/toolkit";
import { fetchIndustriesList, setIndustryList } from "./LogoProductionAction";

interface industryListType{
  error: string | undefined;
  loading:boolean;
  listOfIndustries:[];
}
interface LogoProductionStateType {
data:any;
  industryList:industryListType;
}
const logoProductionInitialState: LogoProductionStateType = {
  data: "",
  industryList:{
    error:"",
    loading:false,
    listOfIndustries:[],
  },
};


const logoProductionSlice = createSlice({
  initialState: logoProductionInitialState,
  name: "logoProduction",
  reducers: {},
  extraReducers(builder) {
    builder.addCase("dfssd", (state, action) => {
      console.log("");
    })
    .addCase(fetchIndustriesList.pending, (state, action) => {
      state.industryList.loading = true;
    })
    .addCase(fetchIndustriesList.fulfilled, (state, action) => {
      state.industryList.listOfIndustries= {
        ...state.industryList.listOfIndustries,
        ...action.payload,

      };
      state.industryList.loading = false;
    })
    
    .addCase(fetchIndustriesList.rejected, (state, action) => {
      state.industryList.error= action.error.message;
      state.industryList.loading = false;
    })
          .addCase(setIndustryList, (state, action) => {
        state.industryList.listOfIndustries = action.payload;
      })
  },
});
export default logoProductionSlice.reducer;

