import { Button, Form, notification, Input, Modal } from "antd";
import { ChangeEvent, useState } from "react";
import "./UploadImageComponentStyles.css";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import {
  fetchBrandDetailImageVerification,
  uploadImageInImageVerification,
} from "../../../redux/ImageVerification/ImageVerificationAction";
import { validateUploadedImage } from "./UploadImageHelper";

type FileUploadModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  brandId: string;
  batchId: string;
  brandName: string;
};

function UploadImageComponent(props: FileUploadModalProps) {
  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const onFinish = () => {
    dispatch(
      uploadImageInImageVerification({
        image: selectedFile,
        brandId: props.brandId,
        batchId: props.batchId,
        brandName: props.brandName,
      })
    ) .then(() => {
      props.close();
    })
      .then(() => {
        return dispatch(
          fetchBrandDetailImageVerification({
            brandId: props.brandId,
            batchId: props.batchId,
          })
        );
      });
     
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0]);
    } else {
      setSelectedFile(null);
    }
  };
  const handleClose = () => {
    props.close();
    setSelectedFile(null);
  };
  return (
    <Modal
    maskClosable={false}
      centered
      zIndex={1500}
      footer={null}
      onCancel={() => {
        props.close()
        setSelectedFile(null);
        form.resetFields();
      }}
      open={props.openState}
    >
      <Form
        form={form}
        name="fileUploadForm"
        onFinish={onFinish}
        colon={false}
        scrollToFirstError
      >
         <div className="uploadImageHeader">
         <h4>Upload Image</h4>
         </div>
        
        <div className="d-flex flex-column">
          <Form.Item
            className="form-item"
            id="file"
            name="file"
            rules={[
              {
                validator: (rule, value, callback) =>
                  validateUploadedImage(rule, selectedFile, callback),
              },
            ]}
          >
            <div className="file-input">
              <Input
                type="file"
                id="file"
                name="file"
                className="form-control"
                onChange={handleFileChange}
              />
              <span className="label required" data-js-label>
                <p>
                  {selectedFile ? selectedFile.name : "Please select a file"}
                </p>
              </span>

              <span className="button">Choose</span>
            </div>
          </Form.Item>
        </div>

        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex gap-4">
            <Button htmlType="submit" className="saveButton" type="primary">
              Submit
            </Button>
            <Button htmlType="reset" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default UploadImageComponent;
