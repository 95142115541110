import { Spin } from "antd";
import React from "react";

function LoadingPage(props: any) {
  return (
    <div
      className="app"
      style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Spin size="large" />
    </div>
  );
}

export default LoadingPage;
