import React from "react";
import WLLogo from "../../../assets/img/william_lea_small.png";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { fetchAnswer, setMessages } from "../../../redux/ChatAI/ChatAIAction";

function InitialChatContainer(props: any) {
  const dispatch = useAppDispatch();
  const question = "Brainstorm documents that related to customer service for williams lea.";
  const suggestedQuestions = useSelector((state: RootState) => state.ChatAIState.suggestedQuestions);

  // const suggestedQuestions: any = [
  //   {
  //     _id: "65ce4fa2abd2fe89f7b90be5",
  //     question: "What is the latest information on ENGAGE?",
  //     description: "Latest information on ENGAGE",
  //     category: "",
  //     title: "Engage",
  //   },
  //   {
  //     _id: "65ce5036abd2fe89f7b90be6",
  //     question: "What are latest services provided by Williams Lea?",
  //     description: "Latest services from Williams Lea",
  //     category: "",
  //     title: "Services by Williams Lea",
  //   },
  //   {
  //     _id: "65ce5045abd2fe89f7b90be7",
  //     question: "Breakdown the revenue generated from different geography in 2020",
  //     description: "Revenue in 2020",
  //     category: "",
  //     title: "Revenue",
  //   },
  //   {
  //     _id: "65ce5059abd2fe89f7b90be8",
  //     question: "List all the awards and recognitions received by Williams Lea",
  //     description: "Recognitions received by Williams Lea?",
  //     category: "",
  //     title: "Awards",
  //   },
  // ];

  return (
    <div className="chat-container initial">
      <div className="d-flex full-flex flex-column justify-content-center align-items-center gap-3">
        <img
          src={WLLogo}
          alt=""
        />
        <h4>How can I help you today?</h4>
      </div>
      <div className="recommend-container row gap-3 justify-content-center px-4">
        {suggestedQuestions.map((question: any, index: number) => {
          return (
            <div
              className="col-md-5 col-12 recommend-question-container border"
              key={index}
              onClick={() => {
                dispatch(setMessages({ message: question.question, owner: "user", sqlQuery: "" }));
                dispatch(fetchAnswer(question.question));
              }}>
              <b>{question.title}</b>
              <p>{question.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InitialChatContainer;
