import { Button, Form, Modal, Select } from "antd";
import Input from "antd/es/input/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { addStockSymbolValidator } from "../../../utils/addStockSymbolValidator";
import { useState } from "react";
import { addSymbol, setIsEdited } from "../../../redux/LivePlatform/LivePlatformAction";

type AddStockSymbolModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  // currentStockSymbolIndex: number;
};

function AddStockSymbolModal(props: AddStockSymbolModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const stockDropdownData = useSelector((state: RootState) => state.livePlatformState.brandDetailState.dropDownData.stockExchange);
  const stockSymbols = useSelector((state: RootState) => state.livePlatformState.brandDetailState.data.stockSymbols);
  const brandDetailState = useSelector((state: RootState) => state.livePlatformState.brandDetailState);
  const AddStockSymbolContent = () => {
    const onFinish = (values: any) => {
      form.resetFields();
      dispatch(addSymbol({ ...values }));
      dispatch(setIsEdited(true));
      props.close();
    };

    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Add Stock Symbol</h4>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <Form
              form={form}
              disabled={brandDetailState.data.isArchived === true }
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{ remember: false }}
              colon={false}>
              <Form.Item
                label="Exchange"
                name="exchangeName"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide exchange.",
                  },
                ]}>
                <Select
                  placeholder="Please select exchange"
                  onChange={() => {
                    if (form.getFieldValue("symbol")) form.validateFields(["symbol"]);
                  }}>
                  {stockDropdownData.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Symbol"
                name="symbol"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide symbol.",
                  },
                  {
                    validator(rule, value, callback) {
                      const ExchangeName = form.getFieldValue("exchangeName");
                      addStockSymbolValidator(rule, value, callback, stockSymbols, ExchangeName);
                    },
                  },
                ]}>
                <Input
                  placeholder="Symbol"
                  onBlur={() => form.validateFields(["exchangeName"])}
                />
              </Form.Item>

              <div className="d-flex gap-3 justify-content-end">
                <Button
                  className="saveButton"
                  type="primary"
                  htmlType="submit">
                  Add
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                  }}
                  // form="createNewFolderForm"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <AddStockSymbolContent />
    </Modal>
  );
}

export default AddStockSymbolModal;
