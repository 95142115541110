// import React, { useEffect, useState } from "react";
import "./CustomImageViewerNavBar.css";
import { FontIcon } from "@fluentui/react";
export interface CustomImageViewerNavBarProps {
  onPrev: () => void;
  onNext: () => void;
  onSelect: (index: number) => void;
  images: { src: string }[];
  activeIndex: number;
}

function CustomImageViewerNavBar(props: CustomImageViewerNavBarProps) {
  const prevIndex = props.activeIndex > 0 ? props.activeIndex - 1 : null;
  const nextIndex = props.activeIndex < props.images?.length - 1 ? props.activeIndex + 1 : null;

  return (
    <div className="custom-navbar">
      <button
        onClick={props.onPrev}
        // disabled={props.activeIndex === 0}
        >
        <FontIcon iconName="ChevronLeftSmall" />
      </button>

      <div className="d-flex gap-5 align-items-center">
        {prevIndex !== null && (
          <img
            src={props.images[prevIndex]?.src}
            alt={`${prevIndex + 1}`}
            className={"prev-img"}
            onClick={() => {
              props.onSelect(prevIndex);
            }}
          />
        )}
        {props.images[props.activeIndex]?.src && (
          <img
            src={props.images[props.activeIndex]?.src}
            className={`active-img `}
            alt={`${props.activeIndex + 1}`}
            onClick={() => {
              props.onSelect(props.activeIndex);
            }}
          />
        )}

        {nextIndex !== null && (
          <img
            src={props.images[nextIndex]?.src}
            alt={`${nextIndex + 1}`}
            className={"next-img"}
            onClick={() => {
              props.onSelect(nextIndex);
            }}
          />
        )}
      </div>

      <button
        onClick={props.onNext}
        // disabled={props.activeIndex === props.images.length - 1}
        >
        <FontIcon iconName="ChevronRightSmall" />
      </button>
    </div>
  );
}

export default CustomImageViewerNavBar;
