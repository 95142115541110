import React, { useLayoutEffect, useState } from "react";

import { Navigate, Outlet } from "react-router-dom";
import Footer from "../components/LayoutComponents/footer/Footer";
import UnAuthNav from "../components/LayoutComponents/nav/UnAuthNav";

// import { isUserLoggedIn } from "../services/auth/AuthAPI";

function UnAuthLayout(props: any) {
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [loading, setLoading] = useState(true);

  // useLayoutEffect(() => {
  //   const checkUserLogin = async () => {
  //     // setLoading(true);
  //     const status = await isUserLoggedIn();

  //     setIsAuthenticated(status);
  //     setLoading(false);
  //   };
  //   checkUserLogin();
  // }, []);
  // if (loading) {
  //   return <>loading</>;
  // }
  // return !isAuthenticated ? (
  //   <div className="app">
  //     <UnAuthNav />
  //     <Outlet />
  //     <Footer />
  //   </div>
  // ) : (
  //   // <Redirect/>
  //   <Navigate to={"/auth"} />
  // );
  return (
    <div className="app">
      <UnAuthNav />
      <Outlet />
      <Footer />
    </div>
  );
}

export default UnAuthLayout;
