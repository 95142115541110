import { Button, Checkbox, Form, Input, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import ImageViewer from "../../ImageViewer/ImageViewer";
import AddStockSymbolModal from "../ModalComponent/AddStockSymbolModal";
import ConfirmDeleteModal from "../ModalComponent/ConfirmDeleteModal";
// import "./BrandQualityCheck.css";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import EditStockSymbolModal from "../ModalComponent/EditStockSymbolModal";
import { saveImageDetail, setIsEdited, acceptImage, openConfirmDeleteModal, closeConfirmDeleteModal, openAddStockSymbolModal, closeAddStockSymbolModal, openEditStockSymbolModal, closeEditStockSymbolModal, setCurrentImageIndex } from "../../../redux/LivePlatform/LivePlatformAction";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 24 },
    xl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 24 },
    xl: { span: 19 },
  },
};

function ImageQualityCheckTab(props: any) {
  const [form] = Form.useForm();
  const [formError, setFormError] = useState(false);
  const brandDetailState = useSelector((state: RootState) => state.livePlatformState.brandDetailState);
  const imageMetaData = brandDetailState.dropDownData.imageMetaData;
  const currentImage = brandDetailState.data.images[brandDetailState.currentImageIndex];
  const [formatType, setFormatType] = useState(currentImage?.format);
  const commentsLength = currentImage?.comments?.length || 0;
  const [commentsCharCount, setCommentsCharCount] = useState(4000 - commentsLength);
  const isEditing: boolean = useSelector((state: RootState) => state.livePlatformState.brandDetailState.isEdited);
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const pageType = "Live";
  const onFinish = (values: any) => {
    dispatch(
      saveImageDetail({
        imageDetail: values,
        imageId: currentImage?.id,
        brandId: props.brandId,
      })
    );
    dispatch(setIsEdited(false));
  };
  const approveImage = () => {
    dispatch(
      acceptImage({
        imageId: currentImage?.id,
        imageDetail: currentImage,
        brandId: props.brandId,
      })
    );
  };

  useEffect(() => {
    form.setFieldsValue(currentImage);
  }, [currentImage, props.tabKey]);

  useEffect(() => {
    setFormatType(currentImage?.format);
  }, [currentImage]);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true, recursive: true, dirty: true })
      .then(() => {
        setFormError(false);
      })
      .catch((error) => {
        if (error.errorFields.length > 0) {
          setFormError(true);
        }
      });

    return () => {
      setFormError(false);
    };
  }, [brandDetailState.currentImageIndex, formatType, currentImage]);

  useEffect(() => {
    setVisible(true);
    return () => setVisible(false);
  }, []);

  return (
    <div
      className="d-flex h-100 flex-column flex-md-row"
      key={2}>
      <ConfirmDeleteModal
        open={() => dispatch(openConfirmDeleteModal())}
        close={() => dispatch(closeConfirmDeleteModal())}
        openState={brandDetailState.openConfirmDeleteModalState}
        brandId={props.brandId}
        imageId={currentImage?.id}
      />
      <AddStockSymbolModal
        open={() => dispatch(openAddStockSymbolModal())}
        close={() => dispatch(closeAddStockSymbolModal())}
        openState={brandDetailState.openStockSymbolModalState}
      />
      <EditStockSymbolModal
        open={() => dispatch(openEditStockSymbolModal())}
        close={() => dispatch(closeEditStockSymbolModal())}
        openState={brandDetailState.openEditStockSymbolModalState}
      />
      <div className="form-container d-flex  justify-content-between   flex-column pe-0 pe-md-4  py-3 ">
        <div className="d-flex flex-column full-flex h-100 overflow-auto">
          <Spin spinning={brandDetailState.saveLoading}>
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              disabled={props.isLocked || props.images.length === 0 ? true : false|| brandDetailState.data.isArchived === true}
              colon={false}
              onValuesChange={() => {
                dispatch(setIsEdited(true));
              }}
              initialValues={{
                residence: ["zhejiang", "hangzhou", "xihu"],
                prefix: "86",
              }}
              // style={{ maxWidth: 600 }}
              scrollToFirstError>
              <Form.Item
                name="type"
                labelAlign="left"
                label="Type"
                rules={[{ required: true, message: "Please select type." }]}>
                <Select placeholder="Please select a type.">
                  {imageMetaData?.ImageTypes.map((e: any) => (
                    <Select.Option
                      key={e.value}
                      value={e.value}>
                      <div>{e.value}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="format"
                label="Format"
                labelAlign="left"
                rules={[{ required: true, message: "Please select format." }]}>
                <Select
                  disabled
                  placeholder="Please select a format."
                  onChange={(e: any) => {
                    setFormatType(e);
                    form.validateFields(["width", "height"]);
                  }}>
                  {imageMetaData?.ImageFormats.map((e: any) => (
                    <>
                      <Select.Option
                        key={e.value}
                        value={e.value}>
                        <div>{e.value}</div>
                      </Select.Option>
                    </>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="opacity"
                labelAlign="left"
                rules={[{ required: true, message: "Please select opacity." }]}
                label={<span>Opacity</span>}>
                <Select placeholder="Please select a opacity.">
                  {imageMetaData?.ImageOpacityItems.map((e: any) => (
                    <Select.Option
                      key={e.value}
                      value={e.value}>
                      <div>{e.value}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="color"
                labelAlign="left"
                rules={[{ required: true, message: "Please select color." }]}
                label={<span>Color</span>}>
                <Select placeholder="Please select color.">
                  {imageMetaData?.ImageColors.map((e: any) => (
                    <Select.Option
                      key={e.value}
                      value={e.value}>
                      <div>{e.value}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                  className="theme"
                  name="theme"
                  rules={[{ required: true, message: "Please select theme." }]}
                labelAlign="left"
                label={
                  <span>
                   Theme
                  </span>
                }>
             <Select placeholder="Please select theme.">
                    {imageMetaData?.ImageThemes?.map((e: any) => (
                    <Select.Option
                      key={e.value}
                      value={e.value}>
                      <div>{e.value}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span className="nonMandatoryInputFields">
                    Dimensions
                    <p className="dimensionsType">(in pixels)</p>
                  </span>
                }
                labelAlign="left"
                // name="dimension"
                style={{ marginBottom: 0 }}>
                <Form.Item
                  name="width"
                  label="Width"
                  required={true}
                  rules={[
                    // { pattern: /^[0-9]*$/, message: "Width cannot be empty" },
                    {
                      validator: (_, value, callback) => {
                        let isInteger = /^\d+$/.test(value);

                        if (value === null || value === undefined || value === "") {
                          return Promise.reject("Please provide width.");
                        } else if (!String(value).trim()) {
                          return Promise.reject("Width cannot be empty.");
                        } else if (value && !isInteger) {
                          return Promise.reject("Please provide valid width.");
                        } else if (Number(value) === 0 && ((formatType && formatType.toLowerCase() === "png") || formatType.toLowerCase() === "jpeg")) {
                          return Promise.reject("Width cannot be zero.");
                        } else if (value > 7500) {
                          return Promise.reject("Max Width : 7500");
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                  style={{
                    display: "inline-block",
                    width: "calc(50% - 8px)",
                    margin: "0 5px",
                  }}>
                  <Input disabled={(formatType && formatType.toLowerCase() === "svg") || props.images.length === 0 ? true : false || brandDetailState.data.isArchived === true} />
                </Form.Item>

                <Form.Item
                  name="height"
                  label="Height"
                  required={true}
                  rules={[
                    // { pattern: /^[0-9]*$/, message: "Height cannot be empty." },
                    {
                      validator: (_, value, callback) => {
                        let isInteger = /^\d+$/.test(value);
                        if (value === null || value === undefined || value === "") {
                          callback("Please provide height.");
                        } else if (!String(value).trim()) {
                          return Promise.reject("Height cannot be empty.");
                        } else if (value && !isInteger) {
                          return Promise.reject("Please provide valid height.");
                        } else if (Number(value) === 0 && ((formatType && formatType.toLowerCase() === "png") || formatType.toLowerCase() === "jpeg")) {
                          callback("Height cannot be zero.");
                        } else if (value > 2500) {
                          callback("Max Height : 2500");
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                  style={{ display: "inline-block", width: "calc(51% - 8px)" }}>
                  <Input disabled={(formatType && formatType.toLowerCase() === "svg") || props.images.length === 0 ? true : false|| brandDetailState.data.isArchived === true} />
                </Form.Item>
              </Form.Item>
              <Form.Item
                name="size"
                label={
                  <span className="sizeLabel">
                    <span>Size</span>
                    <span className="dimensionsType">(in bytes)</span>
                  </span>
                }
                // style={{ border:"1px solid black"}}
                labelAlign="left"
                required={true}
                rules={[
                  //number only rule
                  // { pattern: /^[0-9]*$/, message: "Please provide valid size." },

                  {
                    validator: (rule, value, callback) => {
                      rule.required = true;
                      rule.pattern = /^[0-9]*$/;
                      let isInteger = /^\d+$/.test(value);

                      if (!value) {
                        callback("Please provide size.");
                      } else {
                        if (value && !String(value).trim()) {
                          return Promise.reject("Size cannot be empty.");
                        } else if (value && !isInteger) {
                          return Promise.reject("Please provide valid size.");
                        } else {
                          if (Number(value) === 0) {
                            callback("Size cannot be zero.");
                          } else if (Number(value) > 1048576) {
                            callback("Max Size : 1MB");
                          } else {
                            callback();
                          }
                        }
                      }
                    },
                  },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item
                className="commentsField"
                name="comments"
                labelAlign="left"
                label={
                  <span>
                    <span className="nonMandatoryInputFields"></span>Comments
                  </span>
                }>
                <TextArea
                  onChange={() => {
                    setCommentsCharCount(4000 - form.getFieldValue("comments").length);
                  }}
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  maxLength={4000}
                />
              </Form.Item>

              <div className="imagePageCharCount">{commentsCharCount} remaining characters.</div>
              <Form.Item
                className="archiveField"
                name="isArchived"
                valuePropName="checked"
                labelAlign="left"
                label={
                  <span>
                    <span className="nonMandatoryInputFields"></span>Archive
                  </span>
                }>
                <Checkbox
                  onChange={(e) => {
                    form.setFieldValue("isArchived", e.target.checked);
                  }}></Checkbox>
              </Form.Item>
            </Form>
          </Spin>
        </div>

        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex gap-4">
            <Button
              onClick={() => form.submit()}
              className="saveButton"
              disabled={isEditing ? false : true}
              // disabled={brandDetailState.saveLoading}
              type="primary">
              Save
            </Button>
            <Button
              htmlType="reset"
              // className="action-btn"
              disabled={brandDetailState.saveLoading}
              onClick={() => {
                form.setFieldsValue(brandDetailState.data.images[brandDetailState.currentImageIndex]);
                setCommentsCharCount(4000 - commentsLength);
                setFormatType(currentImage.format);
                form.validateFields(["width", "height"]);
              }}>
              Clear
            </Button>
            {/* <Button htmlType="reset">Cancel</Button> */}
          </div>
        </div>
      </div>

      <div className="responsive-border d-flex flex-column full-flex h-100 pt-3 ps-0 ps-md-4 ">
        {props.images.length > 0 ? (
          <ImageViewer
            brandId={props.brandId}
            batchId={props.batchId}
            brandDetailState={brandDetailState}
            setCurrentImageIndex={(index: number) => {
              dispatch(setCurrentImageIndex(index));
            }}
            pageType={pageType}
            images={props.images}
            id_key={"1"}
            visible={true}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <p className="imageNotFoundMsg">No images available</p>
          </div>
        )}
        {props.images.length > 0 && (
          <div className="d-flex gap-3 my-2 justify-content-end">
            {(currentImage?.status === "Awaiting Qc" || currentImage?.status === "Pre Live") && (
              <Button
                type="primary"
                // className="action-btn"
                disabled={formError ||brandDetailState.data.isArchived === true}
                loading={brandDetailState.imageViewerLoading}
                onClick={() => approveImage()}>
                Accept Image
              </Button>
            )}

            <Button
              type="default"
              className="action-btn"
              loading={brandDetailState.imageViewerLoading}
              disabled={brandDetailState.data.isArchived === true}
              onClick={() => {
                dispatch(openConfirmDeleteModal());
              }}>
              Delete Image
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ImageQualityCheckTab;
