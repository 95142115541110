import React from "react";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { FontIcon } from "@fluentui/react";
import { openDeleteStockSymbolModalForImageVerification, updateStockSymbolForImageVerification } from "../../../redux/ImageVerification/ImageVerificationAction";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";

function OpenDeleteStockSymbolModalBtn(props: any) {
  const dispatch = useAppDispatch();
  const brandStatus = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.status);

  return (
    <FontIcon
      iconName="Cancel"
      style={{ cursor: brandStatus === "Completed" ? "not-allowed" : "pointer" }}
      onClick={() => {
        if (brandStatus === "Completed") return;

        dispatch(openDeleteStockSymbolModalForImageVerification());

        dispatch(updateStockSymbolForImageVerification({ ...props.record, index: props.index }));
      }}
    />
  );
}

export default OpenDeleteStockSymbolModalBtn;
