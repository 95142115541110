import { FontIcon } from "@fluentui/react";

function SortIcon(props: any) {
  switch (props.sortOrder) {
    case "ascend":
      return <FontIcon iconName="ChevronUp" />;
    case "descend":
      return <FontIcon iconName="ChevronDown" />;
    default:
      return <></>;
  }
}

export default SortIcon;
