// import { Avatar } from "antd";
// import React from "react";
// import { useSelector } from "react-redux";
// import { RootState } from "../../../redux/store";
// import { UserOutlined } from "@ant-design/icons";
// import logoImg from "../../../assets/img/william_lea_small.png";
// import { MessageType } from "../../../redux/EngageGPT/EngageGPTSlice";
// import "./ChatContainerComponent.css";
// import { useAppDispatch } from "../../../redux/useAppDispatch";
// import { openSourceDetail, setSourceContent } from "../../../redux/EngageGPT/EngageGPTAction";
// interface ChatContainerComponentProps {
//   messages: Array<MessageType>;
// }

// function ChatContainerComponent(props: ChatContainerComponentProps) {
//   const loading = useSelector((state: RootState) => state.engageGPTState.chatAIMainState.loading);

//   const dispatch = useAppDispatch();
//   return (
//     <div className="chat-container">
//       {/* <h1 style={{ textAlign: "center", color: "grey" }}>ChatAI</h1> */}
//       {props.messages.map((message, index) => {
//         if (message.owner === "user") {
//           return (
//             <div
//               className="human-chat-container"
//               key={index}>
//               <div className="chat-content">
//                 <Avatar
//                   style={{ backgroundColor: "#738fc6", verticalAlign: "middle" }}
//                   // size={"medium"}
//                   shape="square"
//                   icon={<UserOutlined />}
//                   gap={2}
//                 />

//                 <div className="chat">{message.message}</div>
//               </div>
//             </div>
//           );
//         } else {
//           return (
//             <div
//               className="bot-chat-container"
//               key={index}>
//               <div className="chat-content">
//                 <Avatar
//                   style={{ backgroundColor: "transparent", verticalAlign: "middle" }}
//                   // size={"medium"}
//                   className={` ${index === props.messages.length - 1 && loading ? "logo-spinner" : ""}`}
//                   shape="square"
//                   icon={
//                     <img
//                       src={logoImg}
//                       alt="logo"
//                     />
//                   }
//                   gap={2}></Avatar>
//                 <div className="chat">
//                   <p>{message.message}</p>
//                   <div className="d-flex gap-3">
//                     {message.sources.map((sourceContent, index) => {
//                       return (
//                         <button
//                           key={index}
//                           className="source-btn"
//                           onClick={() => {
//                             dispatch(openSourceDetail());
//                             dispatch(setSourceContent({ ...sourceContent, index: index }));
//                             // dispatch(openSourceDetail());
//                             // setSourceData(sourceContent);
//                           }}>
//                           Source {index + 1}
//                         </button>
//                       );
//                     })}
//                   </div>
//                   {message.processingTime > 0 && <p className="time-text">Answer in {message.processingTime} seconds</p>}
//                 </div>
//               </div>
//             </div>
//           );
//         }
//       })}
//       {/* {loading && (
//         <Avatar
//           style={{ backgroundColor: "transparent", verticalAlign: "middle" }}
//           // size={"medium"}
//           className="logo-spinner"
//           shape="square"
//           icon={
//             <img
//               src={logoImg}
//               alt="logo"
//             />
//           }
//           gap={2}></Avatar>
//       )} */}
//     </div>
//   );
// }

// export default ChatContainerComponent;

import { Avatar } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { UserOutlined } from "@ant-design/icons";
import logoImg from "../../../assets/img/william_lea_small.png";
import "./ChatContainerComponent.css";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { RootState } from "../../../redux/Store";
import { openSourceDetail } from "../../../redux/ChatAI/ChatAIAction";
import { MessageType } from "../../../redux/ChatAI/ChatAISlice";

interface ChatContainerComponentProps {
  messages: Array<MessageType>;
}

function ChatContainerComponent(props: ChatContainerComponentProps) {
  const loading = useSelector((state: RootState) => state.ChatAIState.loading);
  const dispatch = useAppDispatch();
  return (
    <div className="chat-container">
      {/* <h1 style={{ textAlign: "center", color: "grey" }}>ChatAI</h1> */}
      {props.messages.map((message, index) => {
        if (message.owner === "user") {
          return (
            <div
              className="human-chat-container"
              key={index}>
              <div className="chat-content">
                <Avatar
                  style={{ backgroundColor: "#738fc6", verticalAlign: "middle" }}
                  // size={"medium"}
                  shape="square"
                  icon={<UserOutlined />}
                  gap={2}
                />

                <div className="chat">{message.message}</div>
              </div>
            </div>
          );
        } else {
          return (
            <div
              className="bot-chat-container"
              key={index}>
              <div className="chat-content">
                <Avatar
                  className={` ${index === props.messages.length - 1 && loading ? "logo-spinner" : ""}`}
                  style={{ backgroundColor: "transparent", verticalAlign: "middle" }}
                  // size={"medium"}

                  shape="square"
                  icon={
                    <img
                      src={logoImg}
                      alt="logo"
                    />
                  }
                  gap={2}></Avatar>
                <div className="chat ">
                  <p>{message.message}</p>
                  <div className="d-flex gap-3">
                    <button
                      key={index}
                      className="source-btn"
                      onClick={() => {
                        dispatch(openSourceDetail());

                        dispatch(openSourceDetail());
                        // setSourceData(sourceContent);
                      }}>
                      Source
                    </button>
                  </div>
                  {/* <div className="d-flex gap-3">
                    {message.sources?.map((sourceContent, index) => {
                      return (
                        <button
                          key={index}
                          className="source-btn"
                          onClick={() => {
                            dispatch(openSourceDetail());
                            dispatch(setSourceContent({ ...sourceContent, index: index }));
                            dispatch(openSourceDetail());
                            // setSourceData(sourceContent);
                          }}>
                          Source {sourceContent.ref_id}
                        </button>
                      );
                    })}
                  </div> */}
                  {/* {message.processingTime > 0 && <p className="time-text">Answer in {message.processingTime} seconds</p>} */}
                </div>
              </div>
            </div>
          );
        }
      })}
      {loading && (
        <Avatar
          style={{ backgroundColor: "transparent", verticalAlign: "middle" }}
          // size={"medium"}
          className="logo-spinner"
          shape="square"
          icon={
            <img
              src={logoImg}
              alt="logo"
            />
          }
          gap={2}></Avatar>
      )}
    </div>
  );
}

export default ChatContainerComponent;
