import { Modal } from "antd";
import React, { useState } from "react";
import AuditEntriesDetails from "./AuditEntriesDetails";

const OpenAuditDetails = (props: { data: any; record: any; index: any }) => {
  // State to manage the visibility of the popup
  const [popupVisible, setPopupVisible] = useState(false);
  // State to store the data of the clicked cell
  const [clickedData, setClickedData] = useState(null);

  // Function to handle click on the ellipsis hyperlink
  const handleEllipsisClick = (data: any) => {
    setPopupVisible(true); // Show the popup
  };

  const { data } = props;
  const fullData = data.split("|||");
  const splitData = data.split("|||").splice(0, 4);
  return (
    <span>
      <div>
        {fullData.length > splitData.length ? (
          <>
          {splitData.map((item:any, index:any) => (
        <div key={index}>{item}</div>
      ))}
      {/* {splitData} */}
            <a href="#" onClick={() => handleEllipsisClick(data)}>
              More
            </a>
          </>
        ) : (
          fullData
        )}
      </div>
      {/* Popup component */}
      {popupVisible && (
        <AuditEntriesDetails
          popupVisible={popupVisible}
          setPopupVisible={() => setPopupVisible(false)}
          index={props.index}
        />
      )}
    </span>
  );
};

export default OpenAuditDetails;
