import { createSlice } from "@reduxjs/toolkit";
import { fetchAnswer, fetchSuggestQuestion, setMessages, clearMessages, openSourceDetail, closeSourceDetail } from "./ChatAIAction";

export interface MessageType {
  message: string;
  owner: "user" | "bot";
  sqlQuery: string;
}
interface ChatAIMainStateType {
  loading: boolean;
  initialLoading: boolean;
  error: any;
  messages: Array<MessageType>;
  sourceDetailOpenState: boolean;
  sourceContent: any;
  suggestedQuestions: Array<any>;
}

const chatAIInitialState: ChatAIMainStateType = {
  loading: false,
  initialLoading: false,
  error: null,
  messages: [],
  sourceDetailOpenState: false,
  sourceContent: {},
  suggestedQuestions: [],
};
const chatAISlice = createSlice({
  initialState: chatAIInitialState,
  name: "ChatAI",
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAnswer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchAnswer.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchAnswer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      })
      .addCase(fetchSuggestQuestion.pending, (state, action) => {
        state.initialLoading = true;
      })
      .addCase(fetchSuggestQuestion.fulfilled, (state, action) => {
        state.suggestedQuestions = action.payload.data;
        state.initialLoading = false;
      })
      .addCase(fetchSuggestQuestion.rejected, (state, action) => {
        state.error = action.error;
        state.initialLoading = false;
      })
      .addCase(setMessages, (state, action) => {
        state.messages = [...state.messages, action.payload];
      })
      .addCase(clearMessages, (state, action) => {
        state.messages = [];
      })
      .addCase(openSourceDetail, (state, action) => {
        state.sourceDetailOpenState = true;
      })
      .addCase(closeSourceDetail, (state, action) => {
        state.sourceDetailOpenState = false;
      });
  },
});
export default chatAISlice.reducer;
