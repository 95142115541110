import React from "react";

// function IsDataNull(props: any) {
//   return <div style={{ whiteSpace: "nowrap" }}>{props.data ? <>{props.data}</> : <>-</>}</div>;
// }
const IsDataNull = (props: any) => {
  const renderData = () => {
    if (!props.data) {
      return "-";
    }

    // If data is an array, render it as comma-separated values
    if (Array.isArray(props.data)) {
      return props.data.join(",");
    }

    return props.data;
  };

  return <div style={{ whiteSpace: "nowrap" }}>{renderData()}</div>;
};
export default IsDataNull;
