import React, { useEffect } from "react";
import "./SourceContainerComponent.css";
import { FontIcon } from "@fluentui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";

function SourceContainerComponent(props: any) {
  const message = useSelector((state: RootState) => state.ChatAIState.messages[state.ChatAIState.messages.length - 1]);
  return (
    <div className="additional-container detail-container d-flex flex-column col-lg-2 col-12 border-start border-end border-bottom">
      <div className="title d-flex align-items-center justify-content-between">
        <h5>
          <b>Source</b>
        </h5>
        <FontIcon
          iconName="Cancel"
          className="close-icon"
          onClick={() => props.close()}
        />
      </div>
      <div className="body-source-container">
        <div>
          <p>
            <b>SQL Query:</b>
          </p>
          <p>{message.sqlQuery}</p>
        </div>
      </div>
    </div>
  );
}

export default SourceContainerComponent;
