import React from "react";
import "./ToggleBtn.css";
import { FontIcon } from "@fluentui/react";

function toggleBtn(props: any) {
  return (
    <button
      className="navbar-toggler"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasWithBothOptions"
      aria-controls="offcanvasWithBothOptions">
      <span className="material-symbols-outlined">menu</span>
    </button>
  );
}

export default toggleBtn;
