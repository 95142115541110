import React from "react";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { openAddStockSymbolModalForImageVerification } from "../../../redux/ImageVerification/ImageVerificationAction";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";

function OpenAddStockSymbolModalBtn(props: any) {
  const brandStatus = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.status);
  const dispatch = useAppDispatch();
  return (
    <span
      role="button"
      // onClick={() => props.openAddModal()}
      style={{ cursor: brandStatus === "Completed" ? "not-allowed" : "pointer" }}
      onClick={() => {
        if (brandStatus !== "Completed") {
          dispatch(openAddStockSymbolModalForImageVerification());
        }
      }}
      className="material-symbols-outlined plus-icon">
      add
    </span>
  );
}

export default OpenAddStockSymbolModalBtn;
