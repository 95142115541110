import { Button, Form, Modal, Select } from "antd";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router";
import { rejectImageForImageVerification, setIsEditedForImageVerification, goLiveForImageVerification } from "../../../redux/ImageVerification/ImageVerificationAction";

type GoLiveConfirmModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  brandId: number;
  imageId: number;
  batchId: number;
  brandName: string;
  loading: boolean;
};

function GoLiveConfirmModal(props: GoLiveConfirmModalProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const brandName = useSelector((state: RootState) => state.imageVerificationState.brandDetailState);
  const brandCountry = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data?.brandLocation?.country);
  const idInBrandLocation = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data?.brandLocation?.id);
  const GoLiveConfirmModalContent = () => {
    // const onFinish = (values: any) => {
    //   dispatch(rejectImageForImageVerification({ id: props.imageId, brandId: props.brandId, batchId: props.batchId, rejectReason: values.reason, remarks: values.remarks }));
    //   dispatch(setIsEditedForImageVerification(true));
    //   form.resetFields();
    //   props.close();
    // };

    // const onFinishFailed = (errorInfo: any) => {
    //   console.log("Failed:", errorInfo);
    // };

    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Go Live</h4>
        <p> Do you want to push '{props.brandName}' to live platform?</p>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-3 justify-content-end">
              <Button
                className="saveButton"
                 type="primary"
                loading={props.loading}
                onClick={async () => {
                  const brandLocation = {
                    id:idInBrandLocation,
                    country:brandCountry,
                    brandId:props.brandId,
                  };
                  dispatch(goLiveForImageVerification({ brandId: props.brandId, batchId: props.batchId,brandLocation:brandLocation}))
                    .then((e) => {
                      if (e.meta.requestStatus === "fulfilled") {
                        props.close();
                        navigate("/image-verification");
                        //   dispatch(updateRequestBodyForImageVerification({ ...requestBody, batchId: props.batchId }));
                      } else {
                        props.close();
                      }
                    })
                    .catch(() => {
                      props.close();

                      console.log("error");
                    });
                }}>
                Yes
              </Button>

              <Button
                htmlType="reset"
                onClick={() => {
                  props.close();
                }}
                // form="createNewFolderForm"
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      //   width={FileUploadModalWidth}
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <GoLiveConfirmModalContent />
    </Modal>
  );
}

export default GoLiveConfirmModal;
