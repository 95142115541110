import React, { useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent/TableComponent";
import Search from "antd/es/input/Search";
import LoadingPage from "../templates/LoadingPage/LoadingPage";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { RootState } from "../../redux/Store";
import { useSelector } from "react-redux";
import ImageVerificationTableColumns from "../../components/ImageVerificationComponent/ImageVerificationTableColumns";
import AutoRefreshCheckboxForImageVerification from "../../components/ImageVerificationComponent/AutoRefreshCheckboxForImageVerification";
import { Select } from "antd";
import { resetImageVerificationTable, updateRequestBodyForImageVerification, fetchBrandsImageVerification, searchImageVerificationBrands, stopAutoRefreshForImageVerification, fetchPaginateBrandsImageVerification } from "../../redux/ImageVerification/ImageVerificationAction";

function ImageVerificationPage(props: any) {
  // const livePlatformPageState = useSelector((state: RootState) => state.livePlatformState);
  const imageVerificationPageState = useSelector((state: RootState) => state.imageVerificationState);

  const [searchValue, setSearchValue] = useState(imageVerificationPageState.currentRequestBody.keyword);
  const [firstFetch, setFirstFetch] = useState(false);
  const dispatch = useAppDispatch();
  const handleTableSorting = (sorter: any) => {
    const isAscend = (order: string) => {
      if (order === "ascend") {
        return true;
      }
      return false;
    };
    const newSortingRequestBody = {
      ...imageVerificationPageState.currentRequestBody,
      sortColumn: sorter.field,
      isAsc: isAscend(sorter.order),
    };
    dispatch(resetImageVerificationTable());
    dispatch(updateRequestBodyForImageVerification(newSortingRequestBody));
    dispatch(fetchBrandsImageVerification({}));
  };
  const onSearch = (value: string) => {
    dispatch(
      searchImageVerificationBrands({
        searchKeyword: value,
      })
    );
  };

  useEffect(() => {
    // dispatch(updateRequestBodyForImageVerification({ ...imageVerificationPageState.currentRequestBody, batchId: imageVerificationPageState.currentBatchId }));
    const fetchBrands = async () => {
      await dispatch(fetchBrandsImageVerification({}));
      setFirstFetch(true);
    };
    fetchBrands();

    return () => {
      dispatch(stopAutoRefreshForImageVerification());
      dispatch(resetImageVerificationTable());
      setFirstFetch(false);
    };
  }, []);
  if (imageVerificationPageState.loading && !firstFetch) {
    return <LoadingPage />;
  }
  return (
    <div className="body-container gap-3">
      <div className="d-flex justify-content-between">
        <h2>Quality Check</h2>
        <div>
          <AutoRefreshCheckboxForImageVerification />
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="search-bar">
          <Search
            type="text"
            size="large"
            value={searchValue}
            onSearch={(value) => onSearch(value)}
            allowClear
            placeholder="Search by Name, Website or Status"
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: 580 }}
            //style to make the content in the center horizontally of the parent position relative
          />
        </div>

        {/* <AutoRefreshCheckbox /> */}
        <div className="d-flex gap-3 align-items-center">
          <div className="d-flex gap-2 align-items-center">
            <span>Batch</span>
            <Select
              style={{ width: 400 }}
              loading={imageVerificationPageState.loading}
              onSelect={(value) => {
                dispatch(resetImageVerificationTable());
                dispatch(updateRequestBodyForImageVerification({ ...imageVerificationPageState.currentRequestBody, batchId: value }));
                dispatch(fetchBrandsImageVerification({}));
              }}
              showSearch
              defaultValue={imageVerificationPageState.currentBatchId}
              options={imageVerificationPageState.batchPickList}
              filterOption={(input, option) => {
                const label = option?.label as string;
                const lowerCaseLabel = label.toLowerCase();
                return lowerCaseLabel.includes(input.toLowerCase());
              }}
            />
          </div>
        </div>
      </div>

      <TableComponent
        columns={ImageVerificationTableColumns}
        dataSource={imageVerificationPageState.data}
        pagination={imageVerificationPageState.pagination}
        handleTableSorting={handleTableSorting}
        loading={imageVerificationPageState.loading}
        fetchPaginatedData={() => fetchPaginateBrandsImageVerification({})}
        tableKey="imageVerificationTableKey"
      />
    </div>
  );
}

export default ImageVerificationPage;
