import React from "react";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { openAddStockSymbolModal } from "../../../redux/LivePlatform/LivePlatformAction";

function OpenAddStockSymbolModalBtn(props: any) {
  const dispatch = useAppDispatch();
  return (
    <span
      role="button"
      // onClick={() => props.openAddModal()}
      onClick={() => {
        dispatch(openAddStockSymbolModal());
      }}
      className="material-symbols-outlined plus-icon">
      add
    </span>
  );
}

export default OpenAddStockSymbolModalBtn;
