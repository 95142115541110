import dev, { DevType } from "./environment.dev";
import prod, { ProdType } from "./environment.prod";

import qa, { QaType } from "./environment.qa";
import staging, { StatingType } from "./environment.staging";
import demo, { DemoType } from "./environment.demo";

const env = process.env.REACT_APP_NODE_ENV;
type environment = StatingType | DevType | ProdType | DemoType | QaType;
let APP_CONFIG: environment;

switch (env) {
  case "dev":
    APP_CONFIG = dev;
    break;
  case "qa":
    APP_CONFIG = qa;
    break;
  case "staging":
    APP_CONFIG = staging;
    break;
  case "prod":
    APP_CONFIG = prod;
    break;
  case "demo":
    APP_CONFIG = demo;
    break;
  default:
    APP_CONFIG = prod;
    break;
}
export default APP_CONFIG;
