import { FontIcon } from "@fluentui/react";
import { Tooltip } from "antd";
import { useCallback, useEffect, useRef } from "react";
import "./ChatInput.css";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { setMessages, fetchAnswer, closeSourceDetail, clearMessages } from "../../../redux/ChatAI/ChatAIAction";

function ChatInput(props: any) {
  const dispatch = useAppDispatch();
  const chatInputRef = useRef(null) as any;
  const chatAiState = useSelector((state: RootState) => state.ChatAIState);

  const onSubmit = (values: any, action: any) => {
    dispatch(setMessages({ message: values.message, owner: "user", sqlQuery: "" }));
    action.setFieldValue("message", "");
    chatInputRef.current.textContent = "";
    dispatch(fetchAnswer(values.message));
    // dispatch(fetchAnswer(values.message));
  };
  const { values, errors, handleChange, handleSubmit, setFieldValue, resetForm, isValid } = useFormik({
    initialValues: {
      message: "",
      selectedPDF: "",
      llmProvider: "",
    },
    onSubmit,

    enableReinitialize: false,
  });

  const scrollToBottom = () => {
    if (props.chatContainerRef.current) {
      props.chatContainerRef.current.scrollTop = props.chatContainerRef.current.scrollHeight;
    }
  };
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey && !chatAiState.loading && values.message.trim() !== "") {
      e.preventDefault();
      handleSubmit();
    }
  };
  const handleInputChange = useCallback(
    (name: string, value: any) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  useEffect(() => {
    scrollToBottom();
  }, [chatAiState.loading]);

  return (
    <div className="chat-box-container">
      <div className="chat-box d-flex gap-2">
        <Tooltip
          title="New chat"
          zIndex={1000}
          placement="bottom">
          <button
            className="action-btn"
            disabled={chatAiState.loading}
            onClick={() => {
              dispatch(closeSourceDetail());
              dispatch(clearMessages());
            }}>
            <FontIcon iconName="CirclePlus" />
          </button>
        </Tooltip>

        <div
          className="chat-box-input  position-relative"
          id="chat-box">
          <div
            contentEditable={"plaintext-only" as any}
            suppressContentEditableWarning={true}
            ref={chatInputRef}
            className="input"
            aria-autocomplete="list"
            onKeyDown={handleKeyPress}
            onInput={(e: any) => handleInputChange("message", e.target.textContent)}
          />

          <button
            className={`send-btn ${chatAiState.loading ? "disabled" : ""}`}
            type="submit"
            onClick={() => !chatAiState.loading && handleSubmit()}
            disabled={values.message.trim() === ""}>
            {chatAiState.loading ? (
              <div className="loading-dot" />
            ) : (
              <FontIcon
                iconName="Send"
                // className="close-icon"
                // size={20}
              />
            )}
          </button>
        </div>
      </div>
      <div>
        <p className="under-text">LogoCloud Q&A can make mistakes. Consider checking important information.</p>
        <p className="under-text grey">Copyright © 2024 Williams Lea. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default ChatInput;
