import { Button } from "antd";
import React from "react";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { useNavigate } from "react-router";
import { openGoLiveConfirmModal } from "../../redux/ImageVerification/ImageVerificationAction";

function GoLiveBtn(props: any) {
  const dispatch = useAppDispatch();
  const loading = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.imageViewerLoading);
  const brandStatus = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.status);
  const navigate = useNavigate();
  return (
    <Button
      type="primary"
      loading={loading}
      disabled={brandStatus === "Completed"}
      onClick={() => {
        dispatch(openGoLiveConfirmModal());
      }}
      className="action-btn">
      Go Live
    </Button>
  );
}

export default GoLiveBtn;
