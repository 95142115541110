import { Button, Form, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useAppDispatch } from "../../../redux/useAppDispatch";
// import { RootState } from "../../../redux/Store";
// import { useSelector } from "react-redux";
import { useState } from "react";
import { rejectImageForImageVerification, setIsEditedForImageVerification } from "../../../redux/ImageVerification/ImageVerificationAction";

type ConfirmRejectModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  brandId: number;
  imageId: number;
  batchId: number;
};

function ConfirmRejectModal(props: ConfirmRejectModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [rejectReason, setRejectReason] = useState("");
  // const brandName = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.name);
  const ConfirmRejectModalContent = () => {
    const onFinish = (values: any) => {
      dispatch(rejectImageForImageVerification({ id: props.imageId, brandId: props.brandId, batchId: props.batchId, rejectReason: values.reason, remarks: values.remarks }));
      dispatch(setIsEditedForImageVerification(false));
      form.resetFields();
      props.close();
    };

    const RejectReason = [
      { value: "Pixelated", label: "Pixelated" },
      { value: "Incorrect Logo", label: "Incorrect Logo" },
      { value: "Incorrect Format", label: "Incorrect Format" },
      { value: "Others", label: "Others" },
    ];
    
    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Reject</h4>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              style={{ maxWidth: 500 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{ remember: false }}
              colon={false}>
              <Form.Item
                label="Reason"
                name="reason"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide the reason.",
                  },
                ]}>
                <Select
                  placeholder="Please select rejection reasons"
                  onChange={(e: any) => {
                    setRejectReason(e);
                    form.setFieldValue("reason", e);
                  }}>
                  {RejectReason.map((option, idx) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}>
                      <div>{option.label}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Remarks"
                labelAlign="left"
                name="remarks"
                rules={[
                  {
                    required: rejectReason === "Others" ? true : false,
                    message: "Please provide remarks if reason is others",
                  },
                ]}>
                <TextArea />
              </Form.Item>
              <div className="d-flex gap-3 justify-content-end">
                <Button
                  className="saveButton"
                  type="primary"
                  htmlType="submit">
                  Confirm
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                  }}
                  // form="createNewFolderForm"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      //   width={FileUploadModalWidth}
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <ConfirmRejectModalContent />
    </Modal>
  );
}

export default ConfirmRejectModal;
