import { FontIcon } from "@fluentui/react";
import { ColumnsType } from "antd/es/table";
import IsDataNull from "../../Template/IsDataNull";
import SortIcon from "../../Template/SortIcon";
import OpenAddStockSymbolModalBtn from "./OpenAddStockSymbolModalBtn";
import OpenEditStockSymbolModalBtn from "./OpenEditStockSymbolModalLink";
import OpenDeleteStockSymbolModalBtn from "./OpenDeleteStockSymbolModalBtn";
interface StockSymbolTableColumnsProperties {
  name: string;
  url: string;
}
// {
//   title: "Name",
//   dataIndex: "name",

//   // defaultSortOrder: "ascend",
//   sorter: true,
//   width: 300,
//   render: (data, record) => (
//     <BrandDetailNavigationLink
//       data={data}
//       record={record}
//     />
//   ),
// },

// export const StockSymbolTableColumns: ColumnsType<StockSymbolTableColumnsProperties> = [
//   {
//     title: "Symbol",
//     key: "name",
//     dataIndex: "symbol",
//     defaultSortOrder: "ascend",

//     sortIcon: (props) => <SortIcon sortOrder={props.sortOrder} />,
//     render: (data,record) => (
//       <div className="link">
//         {/* <IsDataNull data={data} /> */}
//         <OpenEditStockSymbolModalBtn  data={data} record={record}/>
//       </div>
//     ),
//     width: 100,
//   },
//   {
//     title: () => {
//       return (
//         <div className="d-flex justify-content-between">
//           <span>Exchange Name</span>
//           <OpenAddStockSymbolModalBtn />
//         </div>
//       );
//     },
//     key: "url",
//     dataIndex: "exchangeName",
//     render: (data) => (
//       <div>
//         <span>{data}</span> {/* Assuming 'data' is the URL value */}
//         <div className="socialMediaDeleteIcon">
//           <FontIcon iconName="Cancel" />
//         </div>
//       </div>
//     ),
//   },
// ];

export const StockSymbolTableColumns: ColumnsType<StockSymbolTableColumnsProperties> = [
  {
    title: () => {
      return (
        <div className="d-flex justify-content-between">
          <span>Exchange</span>
        </div>
      );
    },

    key: "exchangeName",
    dataIndex: "exchangeName",
    defaultSortOrder: "ascend",

    sortIcon: (props) => <SortIcon sortOrder={props.sortOrder} />,
    render: (data, record) => (
      <>
        <IsDataNull data={data} />
      </>
    ),
    width: 150,
  },
  {
    title: () => {
      return (
        <div className="d-flex justify-content-between">
          <span>Symbol</span>
          <OpenAddStockSymbolModalBtn />
        </div>
      );
    },
    key: "symbol",
    dataIndex: "symbol",
    render: (data, record, index) => (
      <div className="d-flex justify-content-between">
        <div className="link">
          {/* <span>{data}</span>  */}
          <OpenEditStockSymbolModalBtn
            data={data}
            record={record}
            index={index}
          />
        </div>

        <div className="socialMediaDeleteIcon">
          {/* <FontIcon iconName="Cancel" /> */}
          <OpenDeleteStockSymbolModalBtn
            data={data}
            record={record}
            index={index}
          />
        </div>
      </div>
    ),
  },
];
