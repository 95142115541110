import { openEditStockSymbolModal, updateStockSymbol } from "../../../redux/LivePlatform/LivePlatformAction";
import { useAppDispatch } from "../../../redux/useAppDispatch";

function OpenEditStockSymbolModalBtn(props: { data: any; record: any; index: any }) {
  const dispatch = useAppDispatch();

  return (
    <span
      // onClick={() => props.openAddModal()}
      onClick={() => {
        dispatch(updateStockSymbol({ ...props.record, index: props.index }));

        dispatch(openEditStockSymbolModal());
      }}>
      {props.data}
    </span>
  );
}

export default OpenEditStockSymbolModalBtn;
