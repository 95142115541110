import { Button, Form, Modal } from "antd";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import {
  closeDeleteBrandModal,
  DeleteBrand,
  deleteImage,
  setIsEdited,
} from "../../../redux/LivePlatform/LivePlatformAction";
import { useNavigate } from "react-router";

type ConfirmBrandDeleteBrandModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  brandId: number;
};

function ConfirmBrandDeleteModal(props: ConfirmBrandDeleteBrandModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const brandName = useSelector(
    (state: RootState) => state.livePlatformState.brandDetailState.data.name
  );
  const brandId = useSelector(
    (state: RootState) => state.livePlatformState.brandDetailState.data.id
  );
  const navigate = useNavigate();
  const ConfirmBrandDeleteModalContent = () => {
    const onFinish = () => {
      dispatch(DeleteBrand({ brandId: brandId, brandName: brandName })).then(()=>{
        props.close();
        navigate("/live");
      })
 
    };

    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Delete Brand</h4>
        <div className="d-flex flex-column gap-1">
          <p className="d-flex flex-row gap-1">
            <span style={{ color: "red" }}>Warning: </span>
            <span>
              Brand '{brandName}' will be permanently deleted and cannot be
              restored. Do you want to continue?
            </span>
          </p>

          <div className="d-flex flex-column gap-2">
            <Form
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{ remember: false }}
              colon={false}
            >
              <div className="d-flex gap-3 justify-content-end">
                <Button className="saveButton" type="primary" htmlType="submit">
                  Yes
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                    dispatch(closeDeleteBrandModal());
                  }}
                  // form="createNewFolderForm"
                >
                  No
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      //   width={FileUploadModalWidth}
      centered
      zIndex={10000}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}
    >
      <ConfirmBrandDeleteModalContent />
    </Modal>
  );
}

export default ConfirmBrandDeleteModal;
