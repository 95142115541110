import React from "react";
import "./Sidebar.css";
import { useNavigate } from "react-router";

function Sidebar(props: any) {
  const navigate = useNavigate();
  return (
    <div
      className="sidebar offcanvas offcanvas-start border-0"
      data-bs-scroll="true"
      tabIndex={-1}
      id="offcanvasWithBothOptions"
      aria-labelledby="offcanvasWithBothOptionsLabel">
      <div className="offcanvas-header">
        <a
          className="navbar-brand"
          href="/auth">
          <img
            src={require("../../../../assets/img/william_lea_small.png")}
            alt=""
          />
        </a>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"></button>
      </div>
      <div className="offcanvas-body d-flex flex-column">
        <div
          className="sidebar-menu"
          onClick={() => {
            navigate("/auth/documents");
          }}
          data-bs-dismiss="offcanvas"
          aria-label="Close">
          Coming soon
        </div>

        <div className="dropdown border-top mt-auto">
          <button
            className="user-container "
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            <div className="d-flex align-items-center gap-3">
              <span className="material-symbols-outlined">person</span>
              <span>Ly Bunvath</span>
              <span className="material-symbols-outlined">arrow_drop_down</span>
            </div>
          </button>

          <div
            className="dropdown-menu drop-menu-container "
            aria-labelledby="dropdownMenuButton">
            <a
              className="dropdown-item dropdown-item-customize"
              href="/">
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
