import { Button, Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { RootState } from "../../../redux/Store";
import { useSelector } from "react-redux";
import { deleteImage, setIsEdited } from "../../../redux/LivePlatform/LivePlatformAction";

type ConfirmDeleteModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  brandId: number;
  imageId: number;
};

function ConfirmDeleteModal(props: ConfirmDeleteModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const brandName = useSelector((state: RootState) => state.livePlatformState.brandDetailState.data.name);
  const ConfirmDeleteModalContent = () => {
    const onFinish = (values: any) => {
     dispatch(deleteImage({ imageId: props.imageId, brandId: props.brandId }));
      dispatch(setIsEdited(true));
      form.resetFields();
      props.close();
    };


    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Delete</h4>
        <div className="d-flex flex-column gap-1">
          <p> `Do you want to delete the selected image for '{brandName}`?</p>
          <div className="d-flex flex-column gap-2">
            <Form
              name="basic"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={{ remember: false }}
              colon={false}>
              <Form.Item
                label="Remarks"
                name="remarks"
                rules={[
                  {
                    required: true,
                    message: "Please provide remarks.",
                  },
                ]}>
                <TextArea></TextArea>
              </Form.Item>

              <div className="d-flex gap-3 justify-content-end">
                <Button
                  className="saveButton"
                  type="primary"
                  htmlType="submit">
                  Yes
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                  }}
                  // form="createNewFolderForm"
                >
                  No
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      //   width={FileUploadModalWidth}
      centered
      zIndex={10000}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <ConfirmDeleteModalContent />
    </Modal>
  );
}

export default ConfirmDeleteModal;
