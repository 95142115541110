import { createSlice } from "@reduxjs/toolkit";
import { BrandDetailResponseType, DefaultImageVerificationRequestBodyType, defaultImageVerificationRequestBody } from "../../constant/logoProductionType";
import { openNotification } from "../../components/Template/NotificationComponent";
import {
  fetchBrandsImageVerification,
  fetchPaginateBrandsImageVerification,
  setBatchPickList,
  fetchBrandDetailImageVerification,
  saveBrandDetailImageVerification,
  saveBrandIndustryDetailImageVerification,
  saveImageDetailImageVerification,
  setCurrentBatchId,
  setCurrentImageIndexImageVerification,
  approveImageForImageVerification,
  rejectImageForImageVerification,
  goLiveForImageVerification,
  cleanBrandDetailImageVerification,
  fetchAutoRefreshImageVerification,
  switchAutoFreshForImageVerification,
  setAutoRefreshForImageVerification,
  stopAutoRefreshForImageVerification,
  setIsEditedForImageVerification,
  resetImageVerificationTable,
  updateRequestBodyForImageVerification,
  addSymbolForImageVerification,
  editSymbolForImageVerification,
  deleteSymbolForImageVerification,
  updateStockSymbolForImageVerification,
  setStockSymbolsForImageVerification,
  openGoLiveConfirmModal,
  closeGoLiveConfirmModal,
  openUnsavedChangesModalForImageVerification,
  closeUnsavedChangesModalForImageVerification,
  openAddStockSymbolModalForImageVerification,
  closeAddStockSymbolModalForImageVerification,
  openEditStockSymbolModalForImageVerification,
  closeEditStockSymbolModalForImageVerification,
  openDeleteStockSymbolModalForImageVerification,
  closeDeleteStockSymbolModalForImageVerification,
  // setIndustryList,
} from "./ImageVerificationAction";
import { closeRejectImageModal, openRejectImageModal } from "../LivePlatform/LivePlatformAction";

export interface PaginationType {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
}
interface AutoRefreshStateType {
  autoRefresh: boolean;
  currentIntervalId: NodeJS.Timer | undefined;
  error: string | undefined;
  loading: boolean;
}

interface BrandDetailStateType {
  loading: boolean;
  saveLoading: boolean;
  error: string | undefined;
  currentStockSymbol: {
    symbol: string;
    exchangeName: string;
    id: number;
    index: number;
  };
  stockSymbolSelectedToDelete: {
    symbol: string;
    exchangeName: string;
    id: number;
  };
  data: BrandDetailResponseType;
  dropDownData: {
    country: { value: string; label: string }[];
    stockExchange: { value: string; label: string }[];
    imageMetaData: any;
    sectorList:any;
  };
  isEdited: boolean;
  openStockSymbolModalState: boolean;
  openEditStockSymbolModalState: boolean;
  openDeleteStockSymbolModalState: boolean;
  openConfirmRejectModalState: boolean;
  openUnsavedChangesModalState: boolean;
  openGoLiveConfirmModalState: boolean;
  currentImageIndex: number;
  imageViewerLoading: boolean;
}

interface ImageVerificationStateType {
  data: any;
  loading: boolean;
  error: string | undefined;
  currentRequestBody: DefaultImageVerificationRequestBodyType;
  pagination: PaginationType;
  autoRefresh: AutoRefreshStateType;
  brandDetailState: BrandDetailStateType;
  batchPickList: any;
  currentBatchId: number;
}

const imageVerificationInitialState: ImageVerificationStateType = {
  data: [],
  loading: false,
  error: "",
  currentRequestBody: defaultImageVerificationRequestBody,
  currentBatchId: 0,
  batchPickList: [],
  pagination: {
    page: 2,
    pageSize: 30,
    hasMore: true,
    loading: false,
  },
  autoRefresh: {
    autoRefresh: true,
    currentIntervalId: undefined,
    error: "",
    loading: false,
  },
  brandDetailState: {
    loading: false,
    error: "",
    currentStockSymbol: { symbol: "", exchangeName: "", id: 0, index: 0 },
    stockSymbolSelectedToDelete: { symbol: "", exchangeName: "", id: 0 },
    data: {
      id: 0,
      name: "",
      domain: "",
      stockSymbols: [],
      isin: "",
      isArchived:false,
      isDummyDomain:false,
      // country: "",
      status: "",
      batchId: 0,
      description: "",

      images: [
        {
          id: 0,
          type: "",
          src: "",
          opacity: "",
          format: "",
          size: 0,
          height: 0,
          width: 0,
          color: "",
          theme:"",
          status: "",
          comments: "",
          fileName: "",
          isArchived: false,
        },
      ],
      tags: [],
      sectors:[] as Array<any>,
      industries:[],
      brandLocation: {
        id: 4,
        brandId: 314436,
        city: "",
        state: "",
        country: "",
        countryCode: "",
        subRegion: "",
        region: ""
      }
    },
    dropDownData: {
      country: [],
      stockExchange: [],
      imageMetaData: {},
      sectorList:[],
    },
    isEdited: false,
    saveLoading: false,
    openStockSymbolModalState: false,
    openConfirmRejectModalState: false,
    openEditStockSymbolModalState: false,
    openDeleteStockSymbolModalState: false,
    openGoLiveConfirmModalState: false,
    openUnsavedChangesModalState: false,
    currentImageIndex: 0,
    imageViewerLoading: false,
  },
};

const logoProductionSlice = createSlice({
  initialState: imageVerificationInitialState,
  name: "logoProduction",
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchBrandsImageVerification.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBrandsImageVerification.fulfilled, (state, action) => {
        state.data = action.payload.data;

        state.loading = false;
      })
      .addCase(fetchBrandsImageVerification.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(fetchPaginateBrandsImageVerification.pending, (state, action) => {
        state.pagination.loading = true;
      })
      .addCase(fetchPaginateBrandsImageVerification.fulfilled, (state, action) => {
        state.pagination.page = state.pagination.page + 1;
        state.data = state.data.concat(action.payload.data);
        state.pagination.hasMore = false;
        state.pagination.loading = false;
      })

      .addCase(fetchPaginateBrandsImageVerification.rejected, (state, action) => {
        state.error = action.error.message;
        state.pagination.loading = false;
      })

      .addCase(setBatchPickList, (state, action) => {
        state.batchPickList = action.payload;
      })

      .addCase(fetchBrandDetailImageVerification.pending, (state, action) => {
        state.brandDetailState.loading = true;
      })
      .addCase(fetchBrandDetailImageVerification.fulfilled, (state, action) => {
        state.brandDetailState.data = action.payload.brandDetail.data;
        state.brandDetailState.dropDownData = action.payload.dropdownData;
        state.brandDetailState.loading = false;
      })
      .addCase(fetchBrandDetailImageVerification.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.loading = false;
      })
      .addCase(saveBrandDetailImageVerification.pending, (state, action) => {
        state.brandDetailState.saveLoading = true;
      })
      .addCase(saveBrandDetailImageVerification.fulfilled, (state, action) => {
        state.brandDetailState.data = {
          ...state.brandDetailState.data,
          ...action.payload,
          images: state.brandDetailState.data.images,
          sectors: state.brandDetailState.data.sectors,
          industries: state.brandDetailState.data.industries,
        };
        state.brandDetailState.saveLoading = false;
      })
      .addCase(saveBrandDetailImageVerification.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.saveLoading = false;
      })
         //industry
         .addCase(saveBrandIndustryDetailImageVerification.pending, (state, action) => {
          state.brandDetailState.saveLoading = true;
        })
        .addCase(saveBrandIndustryDetailImageVerification.fulfilled, (state, action) => {
          state.brandDetailState.data = {
            ...state.brandDetailState.data, // Retain all existing properties
            ...action.payload, // Overwrite with any new data from the action payload
            images: state.brandDetailState.data.images, // Ensure images are not overwritten
            brandLocation: state.brandDetailState.data.brandLocation // Ensure brandLocation is not overwritten
          };
          state.brandDetailState.saveLoading = false;
        })
        
        .addCase(saveBrandIndustryDetailImageVerification.rejected, (state, action) => {
          state.brandDetailState.error = action.error.message;
          state.brandDetailState.saveLoading = false;
        })
       
      .addCase(saveImageDetailImageVerification.pending, (state, action) => {
        state.brandDetailState.saveLoading = true;
      })

      .addCase(saveImageDetailImageVerification.fulfilled, (state, action) => {
        state.brandDetailState.data.images[state.brandDetailState.currentImageIndex] = {
          ...action.payload,
          src: state.brandDetailState.data.images[state.brandDetailState.currentImageIndex].src,
          status: state.brandDetailState.data.images[state.brandDetailState.currentImageIndex].status,
        };
        state.brandDetailState.saveLoading = false;
      })
      .addCase(setCurrentBatchId, (state, action) => {
        state.currentBatchId = action.payload;
      })
   
      .addCase(saveImageDetailImageVerification.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.saveLoading = false;
      })
      .addCase(setCurrentImageIndexImageVerification, (state, action) => {
        state.brandDetailState.currentImageIndex = action.payload;
      })
      .addCase(approveImageForImageVerification.pending, (state, action) => {
        state.brandDetailState.imageViewerLoading = true;
      })
      .addCase(approveImageForImageVerification.fulfilled, (state, action) => {
        state.brandDetailState.data.images[state.brandDetailState.currentImageIndex] = {
          ...state.brandDetailState.data.images[state.brandDetailState.currentImageIndex],
          status: "Approved",
        };
        state.brandDetailState.currentImageIndex = state.brandDetailState.data.images.length === state.brandDetailState.currentImageIndex + 1 ? 0 : state.brandDetailState.currentImageIndex + 1;
        state.brandDetailState.imageViewerLoading = false;
      })
      .addCase(rejectImageForImageVerification.pending, (state, action) => {
        state.brandDetailState.imageViewerLoading = true;
      })
      .addCase(rejectImageForImageVerification.fulfilled, (state, action) => {
        state.brandDetailState.data.images[state.brandDetailState.currentImageIndex] = {
          ...state.brandDetailState.data.images[state.brandDetailState.currentImageIndex],
          status: "Rejected",
        };
        state.brandDetailState.currentImageIndex = state.brandDetailState.data.images.length === state.brandDetailState.currentImageIndex + 1 ? 0 : state.brandDetailState.currentImageIndex + 1;
        state.brandDetailState.imageViewerLoading = false;
      })
      .addCase(rejectImageForImageVerification.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.imageViewerLoading = false;
      })
      .addCase(goLiveForImageVerification.pending, (state, action) => {
        state.brandDetailState.imageViewerLoading = true;
      })
      .addCase(goLiveForImageVerification.fulfilled, (state, action) => {
        state.brandDetailState.imageViewerLoading = false;
      })
      .addCase(goLiveForImageVerification.rejected, (state, action) => {
        state.brandDetailState.error = action.error.message;
        state.brandDetailState.imageViewerLoading = false;
        openNotification("error", "Error", action.error.message);
      })

      .addCase(cleanBrandDetailImageVerification, (state, action) => {
        state.brandDetailState = imageVerificationInitialState.brandDetailState;
      })
      .addCase(fetchAutoRefreshImageVerification.pending, (state, action) => {
        state.autoRefresh.loading = true;
      })
      .addCase(fetchAutoRefreshImageVerification.fulfilled, (state, action) => {
        state.data = action.payload.data.length < state.data.length ? state.data : action.payload.data;
        state.autoRefresh.loading = false;
      })
      .addCase(fetchAutoRefreshImageVerification.rejected, (state, action) => {
        state.error = action.error.message;
        state.autoRefresh.loading = false;
      })
      .addCase(switchAutoFreshForImageVerification, (state, action) => {
        state.autoRefresh.autoRefresh = action.payload.autoRefresh;
      })
      .addCase(setAutoRefreshForImageVerification, (state, action) => {
        state.autoRefresh.currentIntervalId = action.payload.intervalId;
      })
      .addCase(stopAutoRefreshForImageVerification, (state, action) => {
        clearInterval(state.autoRefresh.currentIntervalId);
      })
      .addCase(setIsEditedForImageVerification, (state, action) => {
        state.brandDetailState.isEdited = action.payload;
      })
      .addCase(resetImageVerificationTable, (state, action) => {
        state = {
          ...state,
          data: state.data,
          currentRequestBody: state.currentRequestBody,
          batchPickList: state.batchPickList,
          autoRefresh: { ...state.autoRefresh },
          currentBatchId: state.currentBatchId,
        };
      })
      .addCase(updateRequestBodyForImageVerification, (state, action) => {
        state.currentRequestBody = action.payload;
      })
      .addCase(addSymbolForImageVerification, (state, action) => {
        state.brandDetailState.data.stockSymbols.push(action.payload);
      })
      .addCase(editSymbolForImageVerification, (state, action) => {
        state.brandDetailState.data.stockSymbols.forEach((element, index) => {
          if (index === state.brandDetailState.currentStockSymbol.index) {
            state.brandDetailState.data.stockSymbols[index] = {
              ...state.brandDetailState.data.stockSymbols[index],
              symbol: action.payload.symbol,
              exchangeName: action.payload.exchangeName,
            };
          }
        });
      })
      .addCase(deleteSymbolForImageVerification, (state, action) => {
        const updatedStockSymbols = state.brandDetailState.data.stockSymbols.filter((element, idx) => idx !== state.brandDetailState.currentStockSymbol.index);
        state.brandDetailState.data.stockSymbols = updatedStockSymbols;
      })
      .addCase(updateStockSymbolForImageVerification, (state, action) => {
        state.brandDetailState.currentStockSymbol = action.payload;
      })
      .addCase(setStockSymbolsForImageVerification, (state, action) => {
        state.brandDetailState.data.stockSymbols = action.payload;
      })
      .addCase(openGoLiveConfirmModal, (state, action) => {
        state.brandDetailState.openGoLiveConfirmModalState = true;
      })
      .addCase(closeGoLiveConfirmModal, (state, action) => {
        state.brandDetailState.openGoLiveConfirmModalState = false;
      })

      .addCase(openUnsavedChangesModalForImageVerification, (state, action) => {
        state.brandDetailState.openUnsavedChangesModalState = true;
      })
      .addCase(closeUnsavedChangesModalForImageVerification, (state, action) => {
        state.brandDetailState.openUnsavedChangesModalState = false;
      })
      .addCase(openAddStockSymbolModalForImageVerification, (state, action) => {
        state.brandDetailState.openStockSymbolModalState = true;
      })
      .addCase(closeAddStockSymbolModalForImageVerification, (state, action) => {
        state.brandDetailState.openStockSymbolModalState = false;
      })
      .addCase(openEditStockSymbolModalForImageVerification, (state, action) => {
        state.brandDetailState.openEditStockSymbolModalState = true;
      })
      .addCase(closeEditStockSymbolModalForImageVerification, (state, action) => {
        state.brandDetailState.openEditStockSymbolModalState = false;
      })
      .addCase(openDeleteStockSymbolModalForImageVerification, (state, action) => {
        state.brandDetailState.openDeleteStockSymbolModalState = true;
      })
      .addCase(closeDeleteStockSymbolModalForImageVerification, (state, action) => {
        state.brandDetailState.openDeleteStockSymbolModalState = false;
      })
      .addCase(openRejectImageModal, (state, action) => {
        state.brandDetailState.openConfirmRejectModalState = true;
      })
      .addCase(closeRejectImageModal, (state, action) => {
        state.brandDetailState.openConfirmRejectModalState = false;
      });
  },
});

export default logoProductionSlice.reducer;
