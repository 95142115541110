import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ChatContainerComponent from "../../components/MainChatPlatformComponent/ChatContainerComponent/ChatContainerComponent";
import ChatInput from "../../components/MainChatPlatformComponent/ChatInputComponent/ChatInput";
import InitialChatContainer from "../../components/MainChatPlatformComponent/InitialChatContainer/InitialChatContainer";
import SourceContainerComponent from "../../components/MainChatPlatformComponent/SourceContainerComponent/SourceContainerComponent";
import { RootState } from "../../redux/Store";
import { useAppDispatch } from "../../redux/useAppDispatch";
import LoadingPage from "../templates/LoadingPage/LoadingPage";
import "./MainChatPlatformPage.css";
import { fetchSuggestQuestion, closeSourceDetail } from "../../redux/ChatAI/ChatAIAction";

export interface LivePlatformPageProps {}

function MainChatPlatformPage(props: any) {
  const chatContainerRef = useRef(null) as any;

  const chatAiState = useSelector((state: RootState) => state.ChatAIState);
  const dispatch = useAppDispatch();
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatAiState.messages]);
  useEffect(() => {
    dispatch(fetchSuggestQuestion({}));
  }, []);
  if (chatAiState.initialLoading) {
    return <LoadingPage />;
  }
  return (
    <div className="d-flex w-100 h-100 overflow-hidden">
      <div
        className="body-container p-0 "
        ref={chatContainerRef}>
        <div className="position-relative  h-100 d-flex flex-column full-flex ">
          {chatAiState.messages.length <= 0 ? <InitialChatContainer suggestedQuestions={chatAiState.suggestedQuestions} /> : <ChatContainerComponent messages={chatAiState.messages} />}

          {/* {chatAiState.loading && (
              <div className="stop-btn-container">
                <button
                  onClick={() => {
                    controller.abort();
                    setLoading(false);
                  }}>
                  Stop generating
                </button>
              </div>
            )} */}
          <ChatInput chatContainerRef={chatContainerRef} />
        </div>
      </div>
      {chatAiState.sourceDetailOpenState && <SourceContainerComponent close={() => dispatch(closeSourceDetail())} />}
    </div>
  );
}

export default MainChatPlatformPage;
